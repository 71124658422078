import ApplicationSerializer from 'later/serializers/application';

import type Store from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports -- we need ModelSchema here for the normalizeResponse and it is not available in @ember-data
import type { ModelSchema } from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';

export default class BrandProfileSerializer extends ApplicationSerializer {
  normalizeResponse(
    store: Store,
    primaryModelClass: ModelSchema<keyof ModelRegistry>,
    payload: { profiles?: { id: string; social_profile_id: string }[] },
    id: string | number,
    requestType: string
  ): object {
    if (payload.profiles) {
      payload.profiles.forEach((profile) => {
        if (!profile.id) {
          profile.id = profile.social_profile_id;
        }
      });
    }

    const transformedPayload = payload.profiles ? { 'brand-profile': payload.profiles } : payload;

    return super.normalizeResponse(store, primaryModelClass, transformedPayload, id, requestType);
  }
}
