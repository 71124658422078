import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Transition } from 'shared/types';

export default class PartnershipsRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;
  @service declare store: Store;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    return this.group?.socialIdentities.firstObject;
  }

  async redirect(_model: unknown, transition: Transition): Promise<void> {
    if (!this.groupSlug) {
      this.router.transitionTo('/');
      return;
    }

    const { campaign_slug, feature, feature_slug } = transition.to.params;
    const { queryParams } = transition.to;
    const influencerId = queryParams.influencer_id;

    let targetGroupSlug = this.groupSlug;
    let targetSocialIdentityId = Number(this.socialIdentity?.id);

    if (influencerId) {
      const influencer = await this.store.findRecord('influencer', Number(influencerId));
      if (influencer) {
        const socialIdentity = await influencer?.get('socialIdentity');
        const group = await socialIdentity?.get('group');
        targetGroupSlug = group?.slug ?? targetGroupSlug;
        targetSocialIdentityId = Number(socialIdentity?.id ?? targetSocialIdentityId);
      }
    }

    if (!targetSocialIdentityId) {
      this.router.transitionTo('cluster.partnerships.profile.index');
      return;
    }

    switch (feature) {
      case 'feature-review':
        this.#handleFeatureReview({
          groupSlug: targetGroupSlug,
          socialIdentityId: targetSocialIdentityId,
          campaign_slug,
          feature_slug
        });
        break;
      case 'submit-status':
        this.#handleSubmitStatus({
          groupSlug: targetGroupSlug,
          socialIdentityId: targetSocialIdentityId,
          campaign_slug
        });
        break;
      case 'submit-content':
        this.#handleSubmitContent({
          groupSlug: targetGroupSlug,
          socialIdentityId: targetSocialIdentityId,
          campaign_slug,
          queryParams
        });
        break;
      default:
        this.#handleDefaultRouting({
          groupSlug: targetGroupSlug,
          socialIdentityId: targetSocialIdentityId,
          campaign_slug,
          queryParams
        });
    }
  }

  #handleFeatureReview(params: {
    groupSlug?: string;
    socialIdentityId?: number;
    campaign_slug?: string;
    feature_slug?: string;
  }): void {
    const { groupSlug, socialIdentityId, campaign_slug, feature_slug } = params;
    if (!groupSlug || !socialIdentityId || !campaign_slug) return;

    if (feature_slug) {
      this.router.transitionTo(
        'cluster.partnerships.profile.view-campaigns.campaign.product-review.review',
        groupSlug,
        socialIdentityId,
        campaign_slug,
        feature_slug
      );
      return;
    }

    this.router.transitionTo(
      'cluster.partnerships.profile.view-campaigns.campaign.product-review',
      groupSlug,
      socialIdentityId,
      campaign_slug
    );
  }

  #handleSubmitStatus(params: { groupSlug?: string; socialIdentityId?: number; campaign_slug?: string }): void {
    const { groupSlug, socialIdentityId, campaign_slug } = params;
    if (!groupSlug || !socialIdentityId || !campaign_slug) return;

    this.router.transitionTo(
      'cluster.partnerships.profile.view-campaigns.campaign.status',
      groupSlug,
      socialIdentityId,
      campaign_slug
    );
  }

  #handleSubmitContent(params: {
    groupSlug?: string;
    socialIdentityId?: number;
    campaign_slug?: string;
    queryParams?: Record<string, unknown>;
  }): void {
    const { groupSlug, socialIdentityId, campaign_slug, queryParams } = params;
    if (!groupSlug || !socialIdentityId || !campaign_slug) return;

    this.router.transitionTo(
      'cluster.partnerships.profile.view-campaigns.campaign.deliverables',
      groupSlug,
      socialIdentityId,
      campaign_slug,
      {
        queryParams
      }
    );
  }

  #handleDefaultRouting(params: {
    groupSlug?: string;
    socialIdentityId?: number;
    campaign_slug?: string;
    queryParams?: Record<string, unknown>;
  }): void {
    const { groupSlug, socialIdentityId, campaign_slug, queryParams } = params;
    if (!groupSlug || !socialIdentityId) {
      return;
    }

    if (campaign_slug === 'manage-campaigns') {
      this.router.transitionTo('cluster.partnerships.profile.manage-campaigns', groupSlug, socialIdentityId);
      return;
    }

    if (campaign_slug === 'unsubscribe') {
      this.router.transitionTo('cluster.partnerships.profile.unsubscribe', groupSlug, socialIdentityId);
      return;
    }

    this.router.transitionTo(
      'cluster.partnerships.profile.view-campaigns.campaign',
      groupSlug,
      socialIdentityId,
      campaign_slug!,
      {
        queryParams
      }
    );
  }
}
