import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.openPost}}\n>\n  <div class=\"o--badge--sm o--badge--danger o--badge--rounded u--m__b__xs\">\n    {{svg-jar \"trash\" class=\"o--icon o--icon--sm o--icon--warning u--m__r__xs\"}}\n    <span>{{t \"shared_words.deleted\"}}</span>\n  </div>\n\n  {{#if this.notificationData.post_caption}}\n    <p class=\"cNO--itemContent__comment u--text--sm\">\n      {{html-safe this.notificationData.post_caption}}\n    </p>\n  {{/if}}\n\n  <Nav::Notification::PostProfile\n    @notificationData={{this.notificationData}}\n    @post={{this.post}}\n    @socialProfile={{this.socialProfile}}\n  />\n</Nav::Notification::Wrapper>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.openPost}}\n>\n  <div class=\"o--badge--sm o--badge--danger o--badge--rounded u--m__b__xs\">\n    {{svg-jar \"trash\" class=\"o--icon o--icon--sm o--icon--warning u--m__r__xs\"}}\n    <span>{{t \"shared_words.deleted\"}}</span>\n  </div>\n\n  {{#if this.notificationData.post_caption}}\n    <p class=\"cNO--itemContent__comment u--text--sm\">\n      {{html-safe this.notificationData.post_caption}}\n    </p>\n  {{/if}}\n\n  <Nav::Notification::PostProfile\n    @notificationData={{this.notificationData}}\n    @post={{this.post}}\n    @socialProfile={{this.socialProfile}}\n  />\n</Nav::Notification::Wrapper>","moduleName":"later/components/nav/notification/post-deleted.hbs","parseOptions":{"srcName":"later/components/nav/notification/post-deleted.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { SegmentEventTypes } from 'later/utils/constants/segment-events';

import type { SafeString } from '@ember/template/-private/handlebars';
import type StoreService from '@ember-data/store';
import type { FeedItem } from '@knocklabs/client';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type NotificationsService from 'later/services/notifications';
import type SegmentService from 'later/services/segment';
import type { Maybe } from 'shared/types';

interface PostDeletedComponentSignature {
  Element: HTMLDivElement;
  Args: {
    close: () => void;
    notification: FeedItem<PostDeletedNotificationData>;
  };
  Blocks: {
    default: [];
  };
}

interface PostDeletedNotificationData {
  group_id: number;
  group_name: string;
  initiator: string;
  post_caption: string;
  post_id: number;
  post_thumbnail_url: string;
  social_profile_id: number;
  social_profile_name: string;
  social_profile_type: string;
  is_draft: boolean;
  scheduled_time: number;
}

export default class PostDeletedComponent extends Component<PostDeletedComponentSignature> {
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare notifications: NotificationsService;
  @service declare segment: SegmentService;
  @service declare store: StoreService;

  get notification(): FeedItem<PostDeletedNotificationData> {
    return this.args.notification;
  }

  get notificationData(): PostDeletedNotificationData | null {
    return this.notification.data;
  }

  get notificationTitle(): SafeString {
    return htmlSafe(
      this.intl.t('notifications.components.post_deleted.title', {
        author: this.notificationData?.initiator || ''
      })
    );
  }

  get socialProfile(): Maybe<SocialProfileModel> {
    if (this.notificationData?.social_profile_id) {
      return this.store.peekRecord('social-profile', this.notificationData.social_profile_id);
    }

    return undefined;
  }

  @action
  openPost(): void {
    this.notifications.markAsRead(this.args.notification);
    this.alerts.alert(this.intl.t('notifications.errors.post_not_found.message'), {
      title: this.intl.t('notifications.errors.post_not_found.title'),
      preventDuplicates: true
    });

    this.segment.track(SegmentEventTypes.OpenedInAppNotification, {
      post_id: String(this.notificationData?.post_id) || '',
      type: 'post-approval-deleted'
    });
  }
}
