import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n{{#if this.showConfirmationModal}}\n  <ConfirmationModal @model={{this.confirmationModalModel}} />\n{{/if}}\n\n{{#if this.showUpsellModal}}\n  <UpsellModal />\n{{/if}}\n\n{{#if this.showInstagramConnectModal}}\n  <IgFacebookConnectModal @model={{this.instagramConnectModalModel}} />\n{{/if}}", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n{{#if this.showConfirmationModal}}\n  <ConfirmationModal @model={{this.confirmationModalModel}} />\n{{/if}}\n\n{{#if this.showUpsellModal}}\n  <UpsellModal />\n{{/if}}\n\n{{#if this.showInstagramConnectModal}}\n  <IgFacebookConnectModal @model={{this.instagramConnectModalModel}} />\n{{/if}}","moduleName":"later/components/dialog-manager.hbs","parseOptions":{"srcName":"later/components/dialog-manager.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type { DialogModel, DialogManagerService } from 'later/services/dialog-manager';

export default class DialogManagerComponent extends Component {
  @service declare dialogManager: DialogManagerService;

  get showConfirmationModal(): boolean {
    return this.dialogManager.showConfirmationModal;
  }

  get confirmationModalModel(): Partial<DialogModel> {
    return this.dialogManager.confirmationModalModel;
  }

  get showUpsellModal(): boolean {
    return this.dialogManager.showUpsellModal;
  }

  get showInstagramConnectModal(): boolean {
    return this.dialogManager.showInstagramConnectModal;
  }

  get instagramConnectModalModel(): Partial<DialogModel> {
    return this.dialogManager.instagramConnectModalModel;
  }
}
