import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import { SocialProfileType } from 'later/utils/constants';
import Step from 'later/utils/onboarding/steps';

import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type OnboardingService from 'later/services/onboarding';

enum ComponentName {
  TeamsSetup = 'calendar/onboarding/wizards/teams-setup',
  BeginOnboarding = 'calendar/onboarding/wizards/begin-onboarding',
  ScheduleFirstPost = 'calendar/onboarding/wizards/schedule-first-post',
  Empty = 'calendar/onboarding/wizards/empty',
  ScheduleFirstPostSuccessful = 'calendar/onboarding/wizards/schedule-first-post-successful'
}

interface OnboardingNode {
  componentName?: ComponentName;
  validSocialProfileTypes: SocialProfileType[];
  validEdges: Step[];
  onTransition: Transition;
}

type Transition = (...args: string[]) => Step;

type OnboardingGraph = {
  [key in Exclude<Step, Step.NotInitialized>]?: OnboardingNode;
} & { [Step.NotInitialized]?: never };

export default class OnboardingWizardManagerService extends Service {
  @service declare auth: AuthService;
  @service declare onboarding: OnboardingService;

  allProfileTypes = [
    SocialProfileType.Instagram,
    SocialProfileType.Facebook,
    SocialProfileType.Twitter,
    SocialProfileType.Pinterest,
    SocialProfileType.Tiktok,
    SocialProfileType.Linkedin
  ];

  onboardingGraph: OnboardingGraph = {
    [Step.TeamsSetup]: {
      componentName: ComponentName.TeamsSetup,
      validSocialProfileTypes: this.allProfileTypes,
      validEdges: [Step.BeginOnboarding],
      onTransition: () => Step.BeginOnboarding
    },
    [Step.BeginOnboarding]: {
      componentName: ComponentName.BeginOnboarding,
      validSocialProfileTypes: this.allProfileTypes,
      validEdges: [Step.ScheduleFirstPost],
      onTransition: () => Step.ScheduleFirstPost
    },
    [Step.ScheduleFirstPost]: {
      componentName: ComponentName.ScheduleFirstPost,
      validSocialProfileTypes: this.allProfileTypes,
      validEdges: [Step.Empty],
      onTransition: () => Step.Empty
    },
    [Step.Empty]: {
      componentName: ComponentName.Empty,
      validSocialProfileTypes: this.allProfileTypes,
      validEdges: [Step.ScheduleFirstPost, Step.FinishedOnboarding],
      onTransition: () => Step.FinishedOnboarding
    },
    [Step.FinishedOnboarding]: {
      validSocialProfileTypes: this.allProfileTypes,
      validEdges: [Step.FinishedOnboarding],
      onTransition: () => Step.FinishedOnboarding
    }
  };

  get socialProfile(): SocialProfileModel {
    return this.auth.currentSocialProfile;
  }

  get currentOnboardingStep(): Step {
    return this.onboarding.currentOnboardingStep;
  }

  get componentName(): string | undefined {
    return this.onboardingGraph[this.currentOnboardingStep]?.componentName;
  }

  @action
  transition(...args: string[]): void {
    const { onTransition, validEdges } = this.onboardingGraph[this.currentOnboardingStep] || {};

    if (this.#isOnTransition(onTransition)) {
      const nextStep = onTransition(...args);
      if (!validEdges?.includes(nextStep)) {
        throw new Error(`${nextStep} is not a valid next step of the current node ${this.currentOnboardingStep}`);
      }
      this.setStep(nextStep);
    }
  }

  @action
  validateSocialProfile(): void {
    const { validSocialProfileTypes } = this.onboardingGraph[this.currentOnboardingStep] || {};

    if (!validSocialProfileTypes) {
      // Note: we should never hit this case unless this.onboardingGraph
      // has a step without validSocialProfileTypes
      throw new Error(`No valid social profile types found for current step ${this.currentOnboardingStep}`);
    }

    if (!this.socialProfile.profileType) {
      throw new Error('No social profile type found');
    }

    if (!this.#isSocialProfileTypeValid(this.socialProfile.profileType)) {
      throw new Error(`Invalid social profile type: ${this.socialProfile.profileType}`);
    }

    if (!validSocialProfileTypes.includes(this.socialProfile.profileType)) {
      this.resetWizard();
    }
  }

  async setStep(nextStep: Step): Promise<void> {
    this.onboarding.setOnboardingStep(nextStep);
  }

  resetWizard(): void {
    this.setStep(Step.BeginOnboarding);
  }

  #isSocialProfileTypeValid(profileType: string): profileType is SocialProfileType {
    return this.allProfileTypes.includes(profileType as SocialProfileType);
  }

  #isOnTransition(transition: Transition | undefined): transition is Transition {
    return (transition as Transition) !== undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'onboarding-wizard-manager': OnboardingWizardManagerService;
  }
}
