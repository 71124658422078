import { readOnly } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const isDraft = readOnly('model.isDraft');

const Validations = buildValidations({
  caption: [
    validator('caption-length', {
      dependentKeys: ['model.isDraft'],
      isWarning: isDraft
    }),
    validator('duplicate-hashtag', {
      field: 'caption',
      isWarning: true,
      dependentKeys: ['model.firstComment', 'model.firstCommentEnabled']
    }),
    validator('hashtag-count', {
      lazy: false,
      field: 'caption',
      dependentKeys: ['model.firstComment', 'model.firstCommentEnabled', 'model.isDraft'],
      isWarning: isDraft
    }),
    validator('mentions-regram', {
      dependentKeys: ['model.firstMediaItem.{labelNames,sourceUsername}', 'model.isDraft', 'model.isOwnPost'],
      isWarning: isDraft
    }),
    validator('short-url-rejector', {
      dependentKeys: ['model.isDraft'],
      presence: true,
      descriptionKey: 'shortUrlRejector',
      isWarning: isDraft
    }),
    validator('invalid-character-rejector', {
      dependentKeys: ['model.isDraft', 'model.socialProfile.isYoutube'],
      isWarning: isDraft
    }),
    validator('threads-multiple-topics', {
      dependentKeys: ['model.caption'],
      isWarning: true
    })
  ],
  firstComment: [
    validator('duplicate-hashtag', {
      field: 'comment',
      isWarning: true,
      dependentKeys: ['model.caption']
    }),
    validator('hashtag-count', {
      lazy: false,
      field: 'comment',
      dependentKeys: ['model.firstComment', 'model.firstCommentEnabled', 'model.isDraft'],
      isWarning: isDraft
    }),
    validator('mention-count', {
      lazy: false,
      dependentKeys: ['model.isDraft', 'model.mentionsLeft', 'model.socialProfile.isInstagram'],
      isWarning: isDraft
    })
  ],
  media: [
    validator('media-required', {
      dependentKeys: [
        'model.caption',
        'model.firstMediaItem',
        'model.isDraft',
        'model.isText',
        'model.socialProfile.canTextOnly',
        'model.socialProfile.isInstagram',
        'model.socialProfile.isTiktok',
        'model.socialProfile.profileType'
      ],
      isWarning: isDraft
    })
  ],
  linkUrl: [
    validator('url-format', {
      lazy: false,
      dependentKeys: ['model.isDraft', 'model.linkUrl', 'model.linkinbioPost.linkUrl'],
      isWarning: isDraft
    }),
    validator('short-url-checker', {
      lazy: false,
      presence: true,
      dependentKeys: ['model.isDraft', 'model.linkUrl'],
      isWarning: isDraft
    }),
    validator('uses-own-lib-url', {
      isWarning: true,
      lazy: false,
      dependentKeys: ['model.linkinbioPost.linkUrl', 'model.socialProfile.linkinbioUrl']
    })
  ],
  text: [
    validator('caption-exists', {
      dependentKeys: ['model.caption', 'model.isDraft', 'model.isText', 'model.socialProfile.canTextOnly'],
      isWarning: isDraft
    })
  ],
  title: [
    validator('title-length', {
      lazy: false,
      dependentKeys: ['model.isDraft', 'model.titleCharactersLeft', 'model.titleCharacterCount'],
      isWarning: isDraft
    }),
    validator('invalid-character-rejector', {
      dependentKeys: ['model.isDraft', 'model.socialProfile.isYoutube'],
      isWarning: isDraft
    })
  ],
  album: [
    validator('board-exists', {
      lazy: false,
      dependentKeys: ['model.album', 'model.isDraft'],
      isWarning: isDraft
    })
  ],
  audience: [
    validator('audience-exists', {
      dependentKeys: ['model.madeForKids', 'model.isDraft'],
      isWarning: isDraft
    })
  ],
  visibility: [
    validator('visibility-exists', {
      dependentKeys: ['model.visibility', 'model.isDraft'],
      isWarning: isDraft
    })
  ],
  tags: [
    validator('youtube-tags-length', {
      dependentKeys: ['model.videoTagCharactersLeft'],
      isWarning: isDraft
    }),
    validator('keyword-tag-length', {
      dependentKeys: ['model.keywordTags'],
      isWarning: isDraft
    }),
    validator('invalid-character-rejector', {
      field: 'keywordTags',
      dependentKeys: ['model.keywordTags', 'model.isDraft', 'model.socialProfile.isYoutube'],
      isWarning: isDraft
    })
  ],
  other: [
    validator('scheduled-or-posted-exists', {
      dependentKeys: ['model.isDraft', 'model.scheduledTime', 'model.postedTime'],
      isWarning: isDraft
    })
  ],
  postMediaItems: [validator('has-many', { debounce: 500, isWarning: isDraft })],
  postType: [
    validator('can-schedule-reel', {
      dependentKeys: ['model.isDraft', 'model.isFacebookReel', 'isInstagramReel'],
      isWarning: isDraft
    })
  ],
  publishingMethod: [
    validator('publishing-method', {
      dependentKeys: ['model.{isNonPublishedPost,autoPublish}'],
      isWarning: isDraft
    })
  ]
});

export default Validations;
