import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import { OauthSocialProfileType } from 'later/utils/constants';
import redirect from 'shared/utils/redirect';

import type GroupModel from 'later/models/group';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { Maybe } from 'shared/types';

export default class ThreadsService extends Service {
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  /**
   * Redirects to Threads OAuth for the specified group or set
   *
   * @method createThreadsWithSet
   */
  createThreadsWithSet(setId: Maybe<string>, group: GroupModel, redirectPath: Maybe<string>): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Threads,
      redirectGroupSlug: group.slug,
      redirectPath
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    threads: ThreadsService;
  }
}
