import Application from '@ember/application';
import 'ember-basic-dropdown/styles';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from 'later/config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;

  Resolver = Resolver;
  engines = {
    auth: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'dialogManager',
          'errors',
          'instagram',
          'intl',
          'laterConfig',
          'linkedin',
          'locale',
          'onboarding',
          'plans',
          'pinterest',
          'router',
          'seamlessCheckoutManager',
          'session',
          'segment',
          'segmentEvents',
          'shared/popup-manager',
          'social/facebook-auth',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions',
          'tiktok',
          'twitter',
          'userConfig'
        ],
        externalRoutes: {
          'account.overview': 'account.overview',
          subscription: 'account.subscription',
          plans: 'plans',
          'plans.plan': 'plans.plan',
          'cluster.schedule': 'cluster.schedule',
          'cluster.collect.contribute': 'cluster.collect.contribute'
        }
      }
    },
    editor: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'dialog-manager',
          'editor',
          'errors',
          'events',
          'intl',
          'media-item-upload',
          'perfect-scroll',
          'performance-tracking',
          'router',
          'seamless-checkout-manager',
          'segment',
          'subscriptions'
        ],
        externalRoutes: {
          media: 'cluster.media.edit',
          calendar_media: 'cluster.schedule.calendar.media',
          multi: 'cluster.schedule.calendar.post.multi',
          new: 'cluster.schedule.calendar.post.new',
          edit: 'cluster.schedule.calendar.post.edit',
          stories: 'cluster.schedule.stories',
          schedule: 'cluster.schedule'
        }
      }
    },
    analytics: {
      dependencies: {
        services: [
          'ad-blocker',
          'alerts',
          'analytics',
          'analytics/analytics-access',
          'auth',
          'cache',
          'categorization-rewards',
          'credential-status',
          'credentials',
          'dialog-manager',
          'analytics/dynamo-analytics',
          'analytics/dynamo-api',
          'errors',
          'events',
          'faye',
          'analytics/formatters/table',
          'analytics/formatters/table/hashtags',
          'analytics/formatters/table/pins',
          'analytics/formatters/table/posts',
          'analytics/formatters/table/reels',
          'analytics/formatters/table/stories',
          'analytics/formatters/table/tweets',
          'analytics/formatters/table/tiktok-posts',
          'analytics/formatters/table/facebook-posts',
          'analytics/formatters/table/threads-posts',
          'analytics/helpers-analytics',
          'industry-data',
          'instagram',
          'analytics/instagram-analytics',
          'analytics/instagram-api',
          'intl',
          'analytics/keen-analytics',
          'analytics/keen-api',
          'analytics/later-analytics',
          'analytics/later-api',
          'laterConfig',
          'linkinbio-page-enabler',
          'localStorageManager',
          'locale',
          'analytics/media-analytics',
          'perfect-scroll',
          'performance-report',
          'performance-tracking',
          'pinterest',
          'router',
          'schedule/post',
          'seamless-checkout-manager',
          'segment',
          'segment-events',
          'selected-social-profiles',
          'session',
          'social/facebook-auth',
          'social/facebook-graph',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions',
          'tiktok',
          'userConfig',
          'wistia-video'
        ],
        externalRoutes: {
          'account.social_profiles': 'account.groups.group.social_profiles',
          contribute: 'cluster.collect.contribute',
          linkinbio: 'cluster.linkinbio',
          'linkinbio.page': 'cluster.linkinbio.page',
          'linkinbio.setup': 'cluster.linkinbio.setup',
          plans: 'plans',
          schedule: 'cluster.schedule',
          'conversations.post': 'cluster.conversations.profile.posts.post'
        }
      }
    },
    calendar: {
      dependencies: {
        services: [
          'ada',
          'alerts',
          'auth',
          'cache',
          'calendar/bttp',
          'calendar/config',
          'calendar/events',
          'calendar/markup',
          'credential-status',
          'dialog-manager',
          'errors',
          'instagram',
          'events',
          'intl',
          'local-storage-manager',
          'locale',
          'media-labels',
          'media-library',
          'media-navigation',
          'onboarding',
          'onboarding-wizard-manager',
          'perfect-scroll',
          'performance-tracking',
          'plans',
          'post-navigation',
          'progress-bar',
          'router',
          'schedule',
          'schedule/caption-hashtags',
          'schedule/calendar/time-slot',
          'schedule/posts-pending-approval',
          'schedule/post',
          'seamless-checkout-manager',
          'secondary-modal',
          'segment',
          'segment-events',
          'selected-social-profiles',
          'social/connect-profiles',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions',
          'tiktok',
          'user-agent',
          'user-config',
          'user-role'
        ],
        externalRoutes: {
          'cluster.media.edit': 'cluster.media.edit'
        }
      }
    },
    collect: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'mediaNavigation',
          'credentials',
          'credential-status',
          'dialog-manager',
          'dropbox',
          'performance-tracking',
          'intl',
          'instagram',
          'instagramGraph',
          'errors',
          'events',
          'laterConfig',
          'locale',
          'local-storage-manager',
          'mediaItemUpload',
          'perfect-scroll',
          'regram',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segmentEvents',
          'selected-social-profiles',
          'social/facebook-auth',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions'
        ],
        externalRoutes: {
          'account.social_profiles': 'account.groups.group.social_profiles',
          schedule: 'cluster.schedule',
          plans: 'plans'
        }
      }
    },
    conversations: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'credential-status',
          'credentials',
          'dialog-manager',
          'errors',
          'events',
          'instagram',
          'intl',
          'later-config',
          'locale',
          'perfect-scroll',
          'performance-tracking',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segment-events',
          'selected-social-profiles',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions',
          'tiktok'
        ],
        externalRoutes: {
          'account.social_profiles': 'account.groups.group.social_profiles',
          plans: 'plans'
        }
      }
    },
    influencers: {
      dependencies: {
        services: [
          'address',
          'alerts',
          'auth',
          'cache',
          'credentials',
          'credential-status',
          'dialog-manager',
          'errors',
          'events',
          'generic-media-manager',
          'instagram',
          'intl',
          'laterConfig',
          'locale',
          'localStorageManager',
          'linkedin',
          'mavely-auth',
          'onboarding',
          'perfect-scroll',
          'performance-report',
          'pinterest',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segmentEvents',
          'session',
          'social/instagram-auth',
          'social/instagram-business',
          'social/facebook-auth',
          'social/facebook-graph',
          'social/connect-profiles',
          'store',
          'subscriptions',
          'tiktok',
          'twitter',
          'userConfig'
        ],
        externalRoutes: {
          schedule: 'cluster.schedule',
          partnership_terms: 'partnership_terms',
          'media-kit.profiles': 'cluster.media-kit.profiles',
          groups: 'account.groups.index',
          'account.overview.edit': 'account.overview.edit'
        }
      }
    },
    'media-kit': {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'credentials',
          'dialog-manager',
          'errors',
          'events',
          'generic-media-manager',
          'instagram',
          'intl',
          'locale',
          'onboarding',
          'perfect-scroll',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segmentEvents',
          'social/connect-profiles',
          'social/instagram-auth',
          'social/instagram-business',
          'social/facebook-auth',
          'social/facebook-graph',
          'store',
          'subscriptions'
        ]
      }
    },
    linkinbio: {
      dependencies: {
        services: [
          'alerts',
          'analytics',
          'analytics/analytics-access',
          'analytics/dynamo-api',
          'analytics/helpers-analytics',
          'analytics/keen-api',
          'analytics/keen-analytics',
          'analytics/later-analytics',
          'analytics/later-api',
          'auth',
          'cache',
          'credentials',
          'credential-status',
          'dialog-manager',
          'errors',
          'events',
          'faye',
          'generic-media-manager',
          'instagram',
          'intl',
          'laterConfig',
          'linkedin',
          'linkinbio-page-enabler',
          'local-storage-manager',
          'locale',
          'mavely-auth',
          'mavely-affiliate-links',
          'media-upload',
          'perfect-scroll',
          'performance-tracking',
          'pinterest',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segment-events',
          'social/instagram-auth',
          'social/instagram-business',
          'social/facebook-graph',
          'social/facebook-auth',
          'social/connect-profiles',
          'store',
          'subscriptions',
          'tiktok',
          'twitter'
        ],
        externalRoutes: {
          'account.social_profiles': 'account.groups.group.social_profiles',
          'account.subscription': 'account.subscription',
          analytics: 'cluster.analytics',
          plans: 'plans',
          'cluster.mobile': 'cluster.mobile',
          partnerships: 'cluster.partnerships.profile',
          campaigns: 'cluster.partnerships.profile.campaigns'
        }
      }
    },
    plans: {
      dependencies: {
        services: [
          'ada',
          'ada-campaigns',
          'address',
          'address-validation',
          'alerts',
          'auth',
          'cancellation',
          'cache',
          'dialogManager',
          'errors',
          'events',
          'integrationsQueue',
          'integrationsSuppressor',
          'intl',
          'laterConfig',
          'locale',
          'payment',
          'performance-tracking',
          'plans',
          'seamlessCheckoutManager',
          'session',
          'segment',
          'segmentEvents',
          'store',
          'stripe',
          'subscriptions',
          'router',
          'userAgent',
          'user-config'
        ],
        externalRoutes: {
          subscription: 'account.subscription',
          'subscription.cancel': 'account.subscription.cancel',
          'user.signup': 'user.signup',
          'user.reset_password': 'user.reset_password',
          'user.forgot_email': 'user.forgot_email'
        }
      }
    },
    labs: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'credential-status',
          'credentials',
          'errors',
          'instagram',
          'instagramGraph',
          'intl',
          'laterConfig',
          'locale',
          'performance-tracking',
          'router',
          'social/facebook-auth',
          'social/facebook-graph',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'userConfig'
        ],
        externalRoutes: {
          'cluster.social-listening': 'cluster.social-listening'
        }
      }
    },
    media: {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'credential-status',
          'credentials',
          'dialogManager',
          'dropbox',
          'errors',
          'events',
          'faye',
          'intl',
          'locale',
          'mediaItemQuery',
          'mediaItemUpload',
          'mediaLabels',
          'mediaLibrary',
          'mediaNavigation',
          'mediaUpload',
          'perfectScroll',
          'queryPersistence',
          'router',
          'selected-social-profiles',
          'seamless-checkout-manager',
          'schedule/caption-hashtags',
          'secondaryModal',
          'segment',
          'starredMedia',
          'store',
          'subscriptions',
          'uploadBus',
          'userConfig'
        ],
        externalRoutes: {
          calendarMedia: 'cluster.schedule.calendar.media',
          contribute: 'cluster.collect.contribute',
          schedule: 'cluster.schedule',
          'account.groups': 'account.groups',
          'cluster.media': 'cluster.media'
        }
      }
    },
    post: {
      dependencies: {
        services: [
          'alerts',
          'ai-credits',
          'auth',
          'cache',
          'calendar/bttp',
          'calendar/config',
          'credentials',
          'credentialStatus',
          'dialog-manager',
          'dropbox',
          'editor',
          'errors',
          'events',
          'faye',
          'instagram',
          'intl',
          'laterConfig',
          'linkinbio-page-enabler',
          'locale',
          'localStorageManager',
          'mediaLibrary',
          'mediaItemQuery',
          'mediaItemUpload',
          'onboarding',
          'perfect-scroll',
          'performance-tracking',
          'pinterest',
          'plans',
          'post-navigation',
          'router',
          'seamless-checkout-manager',
          'schedule',
          'schedule/post',
          'schedule/posts-pending-approval',
          'schedule/caption-hashtags',
          'secondary-modal',
          'segment',
          'segmentEvents',
          'selected-social-profiles',
          'social/facebook-auth',
          'social/facebook-graph',
          'social/instagram-auth',
          'social/instagram-business',
          'social/instagram-product-catalog',
          'social/instagram-product-tagging',
          'social/connect-profiles',
          'social/facebook-auth',
          'store',
          'subscriptions',
          'tiktok',
          'userAgent',
          'userRole',
          'userConfig',
          'youtube'
        ],
        externalRoutes: {
          account: 'account.overview.edit',
          analytics: 'cluster.analytics',
          plans: 'plans',
          schedule: 'cluster.schedule',
          social_profiles: 'account.groups.group.social_profiles'
        }
      }
    },
    'preview-grid': {
      dependencies: {
        services: [
          'alerts',
          'auth',
          'cache',
          'credential-status',
          'dialog-manager',
          'errors',
          'events',
          'instagram',
          'intl',
          'locale',
          'media-labels',
          'media-library',
          'media-navigation',
          'perfect-scroll',
          'router',
          'seamless-checkout-manager',
          'schedule',
          'schedule/caption-hashtags',
          'schedule/posts-pending-approval',
          'schedule/post',
          'secondary-modal',
          'segment',
          'selected-social-profiles',
          'social/facebook-graph',
          'social/instagram-auth',
          'social/instagram-business',
          'store',
          'subscriptions',
          'user-config',
          'user-role'
        ],
        externalRoutes: {
          analytics: 'cluster.analytics',
          calendar: 'cluster.schedule.calendar',
          plans: 'plans',
          schedule: 'cluster.schedule',
          social_profiles: 'account.groups.group.social_profiles',
          stories: 'cluster.schedule.stories',
          'cluster.media.edit': 'cluster.media.edit',
          'cluster.schedule.drafts': 'cluster.schedule.drafts',
          'cluster.schedule.calendar': 'cluster.schedule.calendar',
          'cluster.schedule.stories': 'cluster.schedule.stories',
          'cluster.schedule.list': 'cluster.schedule.list',
          'cluster.schedule.preview-grid': 'cluster.schedule.preview-grid'
        }
      }
    },
    settings: {
      dependencies: {
        services: [
          'ada',
          'ada-campaigns',
          'address',
          'address-validation',
          'alerts',
          'auth',
          'cancellation',
          'cache',
          'categorization-rewards',
          'credential-status',
          'credentials',
          'dialogManager',
          'errors',
          'events',
          'industry-data',
          'instagram',
          'integrationsQueue',
          'intl',
          'laterConfig',
          'linkedin',
          'locale',
          'localStorageManager',
          'mavely-auth',
          'onboarding',
          'payment',
          'perfect-scroll',
          'performance-tracking',
          'pinterest',
          'plans',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segmentEvents',
          'session',
          'social/facebook-auth',
          'social/facebook-graph',
          'social/instagram-auth',
          'social/instagram-business',
          'social/connect-profiles',
          'store',
          'stripe',
          'subscriptions',
          'tiktok',
          'twitter',
          'userConfig',
          'userRole',
          'wistiaVideo'
        ],
        externalRoutes: {
          plans: 'plans',
          'plans.migrate': 'plans.migrate',
          'plans.plan': 'plans.plan',
          'plans.current': 'plans.current',
          'cluster.schedule.calendar': 'cluster.schedule.calendar',
          'user.forgot_email': 'user.forgot_email'
        }
      }
    },
    'social-listening': {
      dependencies: {
        services: [
          'alerts',
          'analytics/helpers-analytics',
          'auth',
          'cache',
          'credential-status',
          'credentials',
          'dialog-manager',
          'errors',
          'events',
          'faye',
          'instagramGraph',
          'intl',
          'laterConfig',
          'localStorageManager',
          'locale',
          'perfect-scroll',
          'progress-bar',
          'router',
          'seamless-checkout-manager',
          'segment',
          'segment-events',
          'session',
          'store',
          'userConfig'
        ],
        externalRoutes: {
          'account.social_profiles': 'account.groups.group.social_profiles',
          'account.social_profiles.new': 'account.groups.group.social_profiles.new',
          'cluster.schedule': 'cluster.schedule'
        }
      }
    },
    styleguide: {
      dependencies: {
        services: ['router']
      }
    }
  };
}

loadInitializers(App, config.modulePrefix);
