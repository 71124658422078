import Service, { inject as service } from '@ember/service';
import { fromPairs as _fromPairs } from 'lodash';

import { FEATURES } from 'shared/utils/analytics/constants';

import type AnalyticsService from '../analytics';
import type AccountModel from 'later/models/account';
import type UserModel from 'later/models/user';
import type { Dictionary } from 'lodash';

export default class AnalyticsAccessService extends Service {
  @service declare analytics: AnalyticsService;

  get currentUser(): UserModel {
    return this.analytics.currentUser;
  }

  get currentAccount(): AccountModel {
    return this.analytics.currentAccount;
  }

  get features(): Dictionary<boolean> {
    return _fromPairs(
      FEATURES.map((feature) => [feature, this.featuresByPlan[feature] && this.featuresByRollout[feature]])
    );
  }

  get isEligibleForTrial(): boolean {
    return !!this.currentAccount?.canTrialPlan;
  }

  // Note: features hard coded to true have no can flag
  get featuresByPlan(): Record<string, boolean> {
    return {
      // Note: legacy plans are included as part of canInstagramPaidAnalytics
      analyticsPerformanceReport: Boolean(this.currentAccount.canAnalyticsPerformanceReport),
      analyticsStandard: Boolean(this.currentAccount.canInstagramPaidAnalytics),
      analyticsTableRedesign: true,
      backfillAnalytics: true,
      clickTracking: Boolean(
        this.currentAccount.canTwitterClickTracking || this.currentAccount.canPinterestClickTracking
      ),
      conversations: Boolean(this.currentAccount.canConversations),
      export: Boolean(this.currentAccount.canExportCsv),
      facebook: Boolean(this.currentAccount.canFacebookAnalytics),
      hashtag: Boolean(this.currentAccount.canHashtagAnalytics),
      hashtagTrends: this.currentAccount.canHashtagTrends || this.currentUser.admin,
      libAnalyticsHeatmap: Boolean(this.currentAccount.canLibAnalyticsHeatmap),
      linkinbioEmbeddable: Boolean(this.currentAccount.canLinkinbioEmbeddable),
      linkinbioExtendedAnalytics: Boolean(this.currentAccount.canLinkinbioExtendedClickAnalytics),
      longerAnalytics: Boolean(this.currentAccount.canLongerAnalytics),
      longerAnalyticsTable: Boolean(this.currentAccount.canLongerAnalytics),
      pinterest: Boolean(this.currentAccount.canPinterestAnalytics),
      pinterestClickTracking: Boolean(this.currentAccount.canPinterestClickTracking),
      profileCategorization: Boolean(this.currentAccount.canProfileCategorizationAnalytics),
      twitter: Boolean(this.currentAccount.canTwitterAnalytics),
      twitterClickTracking: Boolean(this.currentAccount.canTwitterClickTracking),
      tiktokAnalytics: Boolean(this.currentAccount.canTiktokAnalytics),
      instagramReels: this.currentAccount.canInstagramReelsAnalytics || this.currentUser.admin,
      facebookPostPerformance: Boolean(this.currentAccount.rolloutFbPostPerformance),
      threadsAnalytics: Boolean(this.currentAccount.canThreadsAnalytics)
    };
  }

  get featuresByRollout(): Record<string, boolean> {
    return {
      analyticsPerformanceReport: true,
      analyticsStandard: true,
      analyticsTableRedesign: true,
      backfillAnalytics: true,
      clickTracking: true,
      conversations: true,
      export: true,
      facebook: true,
      hashtag: true,
      hashtagTrends: true,
      libAnalyticsHeatmap: true,
      linkinbioEmbeddable: true,
      linkinbioExtendedAnalytics: true,
      longerAnalytics: true,
      longerAnalyticsTable: true,
      pinterest: true,
      pinterestClickTracking: true,
      profileCategorization: true,
      twitter: true,
      twitterClickTracking: true,
      tiktokAnalytics: true,
      threadsAnalytics: true,
      instagramReels: true,
      facebookPostPerformance: Boolean(this.currentAccount.rolloutFbPostPerformance)
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/analytics-access': AnalyticsAccessService;
  }
}
