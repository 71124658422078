import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { VALID_THREADS_TOPIC } from 'later/utils/constants';

@classic
class ThreadsMultipleTopics extends BaseValidator {
  validate() {
    if (!this.model.get('socialProfile.isThreads')) {
      return true;
    }
    const matches = this.model.caption.match(new RegExp(VALID_THREADS_TOPIC, 'g'))?.length ?? 0;
    if (matches > 1) {
      return this.createErrorMessage('threads_multiple_topics');
    }
    return true;
  }
}

export default ThreadsMultipleTopics;
