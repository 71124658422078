import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.onClick}}\n>\n  {{this.defaultMessage}}\n</Nav::Notification::Wrapper>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.onClick}}\n>\n  {{this.defaultMessage}}\n</Nav::Notification::Wrapper>","moduleName":"later/components/nav/notification/generic.hbs","parseOptions":{"srcName":"later/components/nav/notification/generic.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import type { SafeString } from '@ember/template/-private/handlebars';
import type { FeedItem } from '@knocklabs/client';
import type { GenericData } from '@knocklabs/types';
import type NotificationsService from 'later/services/notifications';

interface GenericComponentSignature {
  Element: HTMLDivElement;
  Args: {
    notification: FeedItem<GenericData>;
  };
  Blocks: {
    default: [];
  };
}

export default class GenericComponent extends Component<GenericComponentSignature> {
  @service declare notifications: NotificationsService;

  get notification(): FeedItem<GenericData> {
    return this.args.notification;
  }

  get defaultMessage(): SafeString {
    if (this.notification.blocks[0] && this.notification.blocks[0].type === 'markdown') {
      return htmlSafe(this.notification.blocks[0].rendered);
    }

    return htmlSafe('&nbsp;');
  }

  get notificationTitle(): SafeString {
    return htmlSafe('&nbsp;');
  }

  @action
  onClick(): void {
    this.notifications.markAsRead(this.notification);
  }
}
