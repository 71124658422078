/* eslint-disable unicorn/no-null */
import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { filterTimeSeries } from 'later/utils/array-filters';
import { preciseRound } from 'later/utils/number-helpers';

import type TableService from 'later/services/analytics/formatters/table';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type { Moment } from 'moment';
import type { FormattedDynamoThreadsPost, DefaultThreadsPostRow } from 'shared/types/analytics-data/media';

export default class ThreadsPostsService extends Service {
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/formatters/table') declare tableFormatters: TableService;

  /**
   * Key names to be used to form table columns
   */
  names = ['views', 'reposts', 'likes', 'replies', 'quotes', 'engagements'];

  get defaultRow(): DefaultThreadsPostRow {
    return {
      id: null,
      url: null,
      createdAt: '',
      createdAtTime: '',
      timestampLongFormat: '',
      createdAtUnix: null,
      imageUrl: null,
      views: this.tableFormatters.buildDefaultTableObject(),
      reposts: this.tableFormatters.buildDefaultTableObject(),
      likes: this.tableFormatters.buildDefaultTableObject(),
      replies: this.tableFormatters.buildDefaultTableObject(),
      quotes: this.tableFormatters.buildDefaultTableObject(),
      engagement: this.tableFormatters.buildDefaultTableObject(),
      engagements: this.tableFormatters.buildDefaultTableObject()
    };
  }

  /**
   * Takes an array of posts, formats each post for the
   * posts table according to the given post key names
   *
   * @returns Array of posts formatted for the posts table
   */
  processPosts(
    posts: FormattedDynamoThreadsPost[] = [],
    startDate: Moment,
    endDate: Moment,
    analyticsStandard: boolean
  ): FormattedDynamoThreadsPost[] {
    if (isEmpty(posts) || !Array.isArray(posts)) {
      return posts;
    }

    const filteredPosts = analyticsStandard
      ? filterTimeSeries(posts, startDate.unix(), endDate.unix(), 'createdAtUnix')
      : posts;

    return filteredPosts
      .map((post) => {
        const values = this.tableFormatters.getValues(this.names, post, filteredPosts);
        const tableRow = this.tableFormatters.buildTableRow(values);
        const imageUrl = post?.imageUrl ?? 'assets/images/img--analytics--text-post.png';

        const engagementPercent = post?.engagement ? post.engagement * 100 : undefined;
        const processedPost = Object.assign(
          {},
          {
            id: post.id,
            url: imageUrl,
            thUrl: post?.imageUrl,
            type: post.mediaType || null,
            createdAt: this.helpersAnalytics.createMomentInTz(post.createdAtUnix).format('MMM D, YYYY') || '-',
            createdAtTime: this.helpersAnalytics.createMomentInTz(post.createdAtUnix).format('LT') || '-',
            timestampLongFormat:
              this.helpersAnalytics.createMomentInTz(post.createdAtUnix).format('MMM D, YYYY hh:mm:ss A') || null,
            caption: post.text || null,
            engagement: {
              value: this.tableFormatters.isValid(post.engagement ?? '') ? `${engagementPercent?.toFixed(2)}%` : '-',
              CSVValue: this.tableFormatters.isValid(post.engagement ?? '')
                ? `${preciseRound(engagementPercent ?? 0, 2)}%`
                : null,
              raw: this.tableFormatters.isValid(post.engagement ?? '') ? Number(post.engagement) : null,
              scaled: this.tableFormatters.createElementWidthAttr(post.engagement!)
            },
            createdAtUnix: post.createdAtUnix
          },
          ...tableRow
        );

        return EmberObject.create(processedPost);
      })
      .reverse();
  }
}
