import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Knock from '@knocklabs/client';
import { task } from 'ember-concurrency';

import config from 'later/config/environment';

import type ErrorsService from './errors';
import type StoreService from '@ember-data/store';
import type { Feed, FeedItem, FeedStoreState } from '@knocklabs/client';
import type GroupModel from 'later/models/group';
import type AuthService from 'later/services/auth';

export default class NotificationsService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare store: StoreService;

  @tracked isKnockLoaded = false;
  @tracked knockClient?: Knock = undefined;
  @tracked knockFeed?: Feed = undefined;
  @tracked feed?: FeedStoreState = undefined;

  get knockUserKey(): string {
    return `LS:User:${this.auth.currentUserModel.id}`;
  }

  get feedItems(): FeedItem[] {
    return this.feed?.items || [];
  }

  get unreadFeedItems(): FeedItem[] {
    return this.feedItems.filter((feedItem: FeedItem) => !feedItem.read_at);
  }

  @action
  markAsRead(feedItem: FeedItem): void {
    this.knockFeed?.markAsRead(feedItem);
  }

  @action
  markAllAsRead(): void {
    if (this.feedItems.length) {
      this.knockFeed?.markAllAsRead();
    }
  }

  setup = task(async (currentGroup: GroupModel) => {
    this.isKnockLoaded = false;
    this.knockClient = undefined;
    this.knockFeed = undefined;
    this.feed = undefined;

    try {
      this.knockClient = new Knock(config.APP.knockAppPublicKey);
      this.knockClient.authenticate(this.knockUserKey);

      this.knockFeed = this.knockClient.feeds.initialize(config.APP.knockAppChannelId, {
        archived: 'exclude',
        page_size: 30,
        tenant: `LS:Group:${currentGroup.id}`
      });

      await this.knockFeed.fetch();
      this.#setupKnockListeners();
      this.feed = this.knockFeed.getState();
      await this._fetchFeedItemPosts.perform();

      this.isKnockLoaded = true;
    } catch (error) {
      this.errors.log('Error setting up notification service', error);
    }
  });

  loadMore = task(async () => {
    if (this.knockFeed) {
      await this.knockFeed.fetchNextPage();
    }
  });

  _fetchFeedItemPosts = task(async () => {
    if (!this.feedItems.length) {
      return;
    }

    await this.store.query('gram', {
      query_type: 'all_by_id',
      ids: this.feedItems
        .filter((feedItem: FeedItem) => feedItem.data?.post_id)
        .map((feedItem: FeedItem) => feedItem.data?.post_id)
    });
  });

  #setupKnockListeners(): void {
    if (this.knockFeed) {
      this.knockFeed.listenForUpdates();

      this.knockFeed.on('items.*', () => {
        this.feed = this.knockFeed!.getState();
      });

      this.knockFeed.on('items.received.*', () => {
        this.feed = this.knockFeed!.getState();
        this._fetchFeedItemPosts.perform();
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
