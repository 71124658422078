import { computed, get } from '@ember/object';
import { equal, notEmpty, not, alias, and } from '@ember/object/computed';
import { throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isNone, isBlank, isPresent, isEmpty } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isTesting } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { apiAction } from '@mainmatter/ember-api-actions';
import { collectionAction, memberAction } from 'ember-api-actions';
import { dropTask } from 'ember-concurrency';
import { isNumber } from 'lodash';
import moment from 'moment';

import { TIKTOK_MAX_IMAGES } from 'later/utils/constants';
import states from 'later/utils/social-profiles/states';
import { convert, timestamp } from 'later/utils/time-format';
import { SocialPlatformType } from 'shared/types/social-profile';
import { hasLinkinbioFeedBlock } from 'shared/utils/has-linkinbio-feed-block';

import type LinkinbioPageModel from './linkinbio-page';
import type { AsyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type AnalyticsReportModel from 'later/models/analytics-report';
import type DeviceModel from 'later/models/device';
import type GramModel from 'later/models/gram';
import type GroupModel from 'later/models/group';
import type SharePlanModel from 'later/models/share-plan';
import type SocialIdentityModel from 'later/models/social-identity';
import type TimeSlotModel from 'later/models/time-slot';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type InstagramAuthService from 'later/services/social/instagram-auth';
import type { Moment } from 'moment';
import type { EmptyObject, Maybe, UntypedService } from 'shared/types';
import type {
  AutoScheduleParams,
  AutoScheduleResponse,
  CollectMentionsResponse,
  FacebookTokenDebugParam,
  FacebookTokenDebugResponse,
  HashtagLastSearchedParams,
  HashtagLastSearchedResponse,
  MarkHashtagParam,
  MultiImageMaxCount,
  PagesSearchParam,
  PagesSearchResponse,
  PinterestBoardParam,
  PinterestBoardsResponse,
  PreviewGridParams,
  PreviewGridResponse,
  ProfileAuthenticationState,
  ProfileAuthenticationStates,
  RefreshProfileResponse
} from 'shared/types/social-profile';
import type { YoutubeCategoryResource } from 'shared/types/youtube';

export enum InstagramProfileTypes {
  Business = 'Business',
  Creator = 'Creator',
  Personal = 'Personal'
}

type InstagramProfileType = keyof typeof InstagramProfileTypes;

export default class SocialProfileModel extends Model {
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service('errors') declare errorService: ErrorsService;
  @service('social/instagram-auth') declare instagramAuth: InstagramAuthService;
  @service('social/instagram-business') declare instagramBusiness: UntypedService;

  @attr('string') declare account_id?: string;
  @attr declare additionalPermissionScope: Maybe<string[]>;
  @attr('boolean', { defaultValue: false }) declare analyticsEmail: boolean;
  @attr('number') declare authenticateUserId: Maybe<number>;
  @attr('number') declare authorizingFacebookDataAccessExpiresTime: Maybe<number>;
  @attr('number') declare authorizingFacebookTokenExpiresTime: Maybe<number>;
  @attr('number') declare authorizingFacebookUid: Maybe<number>;
  @attr('boolean', { defaultValue: false }) declare autoPublishFollowUp: boolean;
  @attr('string') declare readonly avatarUrl?: string;
  @attr('boolean', { defaultValue: false }) declare bttpV2Eligible: boolean;
  @attr('string') declare basicDisplayId: Maybe<string>;
  @attr('string') declare basicDisplayToken: Maybe<string>;
  @attr('number') declare basicDisplayTokenExpiresTime: Maybe<number>;
  @attr('string', { defaultValue: '' }) declare readonly bio: string;
  @attr('string', { defaultValue: '' }) declare readonly shortBio: string;
  @attr('string') declare businessAccountId: Maybe<string>;
  @attr('string') declare businessAccountToken: Maybe<string>;
  @attr('string') declare businessModel: Maybe<string>;
  @attr('string') declare category: Maybe<string>;
  @attr('string') declare readonly channelCountry?: string;
  @attr('number') declare collectMentionsTimestamp: Maybe<number>;
  @attr('boolean', { defaultValue: false }) declare readonly contributor: boolean;
  @attr('number') declare readonly createdTime?: number;
  @attr('string') declare readonly credentialRefreshError: Maybe<string>;
  @attr('boolean', { defaultValue: true }) declare defaultAutoPublish: boolean;
  @attr('string', { defaultValue: '' }) declare readonly displayName: string;
  @attr('string', { defaultValue: '' }) declare readonly displayNickname: string;
  @attr('string') declare facebookPageId: Maybe<string>; // only used for Instagram business profiles -iMack
  @attr('string') declare facebookProfileType: Maybe<string>;
  @attr('number') declare followedBy: Maybe<number>;
  @attr('boolean', { defaultValue: false }) declare hasAuthorizingFacebookToken: boolean;
  @attr('boolean', { defaultValue: true }) declare hideProfileCategorizeBanner: boolean;
  @attr('string') declare industry: string | undefined;
  @attr('number') declare industryLastChangedTime: Maybe<number>;
  @attr('string') declare temporaryIndustry?: string;
  @attr('boolean', { defaultValue: false }) declare receivedBttpDiscovery: boolean;
  @attr('boolean', { defaultValue: false }) declare readonly hasPublishingMethod: boolean;
  @attr('string') declare shorttermUserToken?: string; // Create Instagram with FB Login

  // Attributes specific to TikTok
  @attr('number') declare verifiedAt: Maybe<number>; // Timestamp of when the user connected to the last TikTok API
  @attr('string') declare refreshToken: Maybe<string>; // 24 hour token for TikTok, YT Shorts and Pinterest
  @attr('number') declare businessRefreshTokenExpiresTime?: number; // TikTok Business API
  @attr('string', { defaultValue: '' }) declare tiktokAccountType: string;

  @attr('boolean', { defaultValue: false }) declare isBusiness: boolean;
  @attr('string', { defaultValue: '' }) declare instagramAccountType: string;

  @equal('instagramAccountType', 'BUSINESS') declare isInstagramBusiness: boolean;
  @equal('instagramAccountType', 'MEDIA_CREATOR') declare isCreator: boolean;

  @attr('boolean', { defaultValue: false }) declare isFbPage: boolean;
  @attr('string') declare readonly keenReadKey: Maybe<string>;
  @attr('string') declare linkedinProfileType: Maybe<string>;
  @attr('boolean', { defaultValue: false }) declare linkinbioEnabled: boolean;
  @attr('string') declare readonly name?: string;
  @attr('string') declare nickname?: string;
  @attr('string') declare otherBusinessModel: Maybe<string>;
  @attr('string') declare otherIndustry: Maybe<string>;
  @attr declare permissionScope?: string[];
  @attr('string') declare profileColorClass?: string;
  @attr('string') declare profileType?: string;
  @attr('string') declare secretToken?: string;
  @attr('string') declare token?: string;
  @attr('number') declare tokenExpiresTime: Maybe<number>;
  @attr('string') declare readonly type?: string;
  @attr('boolean', { defaultValue: false }) declare receivedInfluencersEducation: boolean;
  @attr('number') declare refreshTokenExpiresTime?: number; // Pinterest API
  @attr('string') declare uid?: string;
  @attr('string') declare readonly urlName: string;
  @attr('string') declare readonly website: Maybe<string>;

  @belongsTo('account', { async: true }) declare account: AsyncBelongsTo<AccountModel>;
  @hasMany('analyticsReport', { async: true }) declare analyticsReports: AsyncHasMany<AnalyticsReportModel>;
  @hasMany('device', { async: true }) declare devices: AsyncHasMany<DeviceModel>;
  @hasMany('gram', { async: true }) declare grams: AsyncHasMany<GramModel>;
  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @hasMany('sharePlan', { async: true }) declare sharePlans: AsyncHasMany<SharePlanModel>;
  @hasMany('timeSlot', { async: true }) declare timeSlots: AsyncHasMany<TimeSlotModel>;
  @belongsTo('socialIdentity', { async: true }) declare socialIdentity: AsyncBelongsTo<SocialIdentityModel>;

  @equal('profileType', 'facebook') declare isFacebook: boolean;
  @equal('profileType', 'instagram') declare isInstagram: boolean;
  @equal('profileType', 'linkedin') declare isLinkedin: boolean;
  @equal('profileType', 'pinterest') declare isPinterest: boolean;
  @equal('profileType', 'threads') declare isThreads: boolean;
  @equal('profileType', 'tiktok') declare isTiktok: boolean;
  @equal('profileType', 'twitter') declare isTwitter: boolean;
  @equal('profileType', 'youtube') declare isYoutube: boolean;

  @and('isInstagram', 'account.canLinkinbioStandard') declare canLinkinbioStandard: boolean;
  @and('isFacebook', 'expiringToken') declare facebookTokenExpired: boolean;
  @alias('isInstagram') declare canMixVideoAndImage: boolean;
  @equal('linkedinProfileType', 'personal') declare isLinkedinPersonalProfile: boolean;
  @notEmpty('devices') declare hasDevices: boolean;
  @notEmpty('facebookPageId') declare hasFacebookPage: boolean;
  @notEmpty('businessAccountId') declare hasProfessionalAccount: boolean;
  @notEmpty('postsLeft') declare hasLimitedPosts: boolean;
  @notEmpty('token') declare hasToken: boolean;
  @not('hasLimitedPosts') declare hasUnlimitedPosts: boolean;

  // The formatted url for a profile's social platform, currently used within Linkin.bio
  // Platforms that display handles will store a handle, parsed from the full url
  // Platforms that do not use handles will store the full url
  @tracked formattedSocialPlatformUrl: string | undefined = undefined;

  // Determines whether or not the social profile is selected for auto import of social links within Linkin.bio
  @tracked isSelectedForLinkinbioAutoImport = false;

  // The full url for a social platform, currently used within Linkin.bio to render auto-imported social links from a profile
  @tracked rawSocialPlatformUrl: string | undefined = undefined;

  autoSchedule = memberAction<AutoScheduleParams, AutoScheduleResponse>({ path: 'auto_schedule' });
  collectMentions = memberAction<never, CollectMentionsResponse>({ type: 'POST', path: 'collect_mentions' });
  facebookTokenDebug = memberAction<FacebookTokenDebugParam, FacebookTokenDebugResponse>({
    type: 'GET',
    path: 'facebook_token_debug'
  });
  getPendingApprovalCount = collectionAction<{ ids: string[] }>({
    type: 'GET',
    path: 'pending_approval_count'
  });
  hashtagLastSearched = memberAction<HashtagLastSearchedParams, HashtagLastSearchedResponse>({
    type: 'GET',
    path: 'hashtag_last_searched'
  });
  markHashtag = memberAction<MarkHashtagParam, EmptyObject>({ type: 'POST', path: 'mark_hashtag' });
  pagesSearch = memberAction<PagesSearchParam, PagesSearchResponse>({ type: 'GET', path: 'pages_search' });
  pinterestBoards = memberAction<PinterestBoardParam, PinterestBoardsResponse>({ type: 'GET', path: 'boards' });
  previewGrid = memberAction<PreviewGridParams, PreviewGridResponse>({ path: 'preview_grid' });

  upsertKeenReadKey = memberAction<never, EmptyObject>({ type: 'POST', path: 'upsert_keen_read_key' });
  youtubeCategories = memberAction<never, YoutubeCategoryResource[]>({
    type: 'GET',
    path: 'youtube_video_categories'
  });

  declare _states: ProfileAuthenticationStates;

  get autoEnableBttp(): boolean {
    return Boolean(this.account.get('canBttpV2') && this.bttpV2Eligible);
  }

  get canCarousel(): boolean {
    if (this.isLinkedin) {
      return !this.isLinkedinPersonalProfile;
    }
    return this.isInstagram || this.isTwitter || this.isFacebook || this.isTiktok || this.isThreads;
  }

  get hasUserTimeSlots(): boolean {
    return isPresent(this.userTimeSlots);
  }

  get canBoostPost(): boolean {
    return this.isInstagram && this.isInstagramBusiness && this.hasFacebookPage;
  }

  get linkinbioPage(): LinkinbioPageModel | undefined {
    return this.group.get('linkinbioPages')?.find((page) => hasLinkinbioFeedBlock(page, this));
  }

  get userTimeSlots(): TimeSlotModel[] {
    const hasNoTimeSlots = isEmpty(this.timeSlots);
    const hasDestroyedTimeSlots = this.timeSlots?.any((timeSlot) => timeSlot.isDestroyed);
    return hasNoTimeSlots || hasDestroyedTimeSlots ? [] : this.timeSlots.filterBy('isUser');
  }

  @computed('profileType')
  get capitalizedProfileType(): string {
    if (this.profileType) {
      return capitalize(this.profileType);
    }
    return '';
  }

  /**
   * Returns whether the socialProfile has minimum permissions required to auto publish
   */
  @computed('isInstagram', 'isBusiness', 'hasInstagramAutoPublishScope', 'needsProfessionalTokenRefresh')
  get canInstagramAutoPublish(): boolean {
    return (
      this.isInstagram &&
      this.hasProfessionalAccount &&
      this.hasInstagramAutoPublishScope &&
      !this.needsProfessionalTokenRefresh
    );
  }

  @computed('isFacebook', 'isInstagram')
  get canReels(): boolean {
    return this.isFacebook || this.isInstagram;
  }

  /**
   * Returns whether the socialProfile is an Instagram profile whose token is expiring or expired
   */
  @computed('isInstagram', 'expiringToken', 'expiringUserToken', 'expiringDataAccessToken')
  get instagramBusinessTokenExpiring(): boolean {
    return this.isInstagram && (this.expiringToken || this.expiringUserToken || this.expiringDataAccessToken);
  }

  @computed('isBusiness', 'isCreator', 'account.rolloutLinkinbioLimitedInstagramPermissions')
  get isProfessional(): boolean {
    return (
      this.isBusiness || this.isCreator || Boolean(this.account.get('rolloutLinkinbioLimitedInstagramPermissions'))
    );
  }

  get linkinbioUrl(): string | undefined {
    return this.linkinbioPage?.url;
  }

  /**
   * Returns whether the socialProfile is an Instagram profile whose token is missing, expired or expiring
   */
  @computed('isInstagram', 'basicDisplayToken', 'expiringBasicDisplayToken')
  get needsBasicDisplayTokenRefresh(): boolean {
    if (this.auth.currentAccount?.rolloutIgWithFbLoginOauth) {
      return false;
    }
    return this.isInstagram && (isBlank(this.basicDisplayToken) || this.expiringBasicDisplayToken);
  }

  /**
   * Returns whether the socialProfile is a Pinterest profile whose token is missing, expired or expiring
   */
  @computed('isPinterest', 'refreshToken', 'expiringRefreshToken')
  get needsRefreshTokenRefresh(): boolean {
    return this.isPinterest && (isBlank(this.refreshToken) || this.expiringRefreshToken);
  }

  /**
   * Returns whether the socialProfile is an Instagram profile whose business token has been invalidated
   * or the authorizing facebook user token is missing
   */
  @computed('isInstagram', 'hasProfessionalAccount', 'businessAccountToken', 'hasAuthorizingFacebookToken')
  get needsProfessionalTokenRefresh(): boolean {
    if (!this.isInstagram) {
      return false;
    }
    if (this.isProfessional) {
      return isBlank(this.businessAccountToken) || !this.hasAuthorizingFacebookToken;
    }
    return false;
  }

  get needsBusinessUpgradeToAutoPublish(): boolean {
    if (this.isInstagram) {
      return !this.canInstagramAutoPublish && this.isProfessional;
    }

    if (this.isTiktok) {
      return this.isTiktokLoginKitAccount && this.canConnectTiktokApi;
    }

    return false;
  }

  /**
   * For Instagram, check both tokens.
   * For tiktok, return false when no token required.
   * For other platforms, check token.
   */
  @computed(
    'needsBasicDisplayTokenRefresh',
    'needsProfessionalTokenRefresh',
    'needsRefreshTokenRefresh',
    'token',
    'isInstagram',
    'isTiktok',
    'needsTiktokRefresh'
  )
  get hasError(): boolean {
    if (this.isTiktok) {
      return this.needsTiktokRefresh;
    }

    if (this.needsBasicDisplayTokenRefresh || this.needsProfessionalTokenRefresh) {
      return true;
    }

    if (this.needsRefreshTokenRefresh) {
      return true;
    }
    return isBlank(this.token) && !this.isInstagram;
  }

  @computed('hasError')
  get errorText(): string {
    return this.hasError ? this.intl.t('account.social_accounts.errors.refresh') : '';
  }

  /**
   * Check if socialProfile needs refresh.  Like hasError but does not check both tokens for Instagram.
   */
  @computed(
    'isInstagram',
    'isTiktok',
    'isFacebook',
    'hasProfessionalAccount',
    'needsTiktokRefresh',
    'needsProfessionalTokenRefresh',
    'needsBasicDisplayTokenRefresh',
    'needsRefreshTokenRefresh',
    'token',
    'tokenExpiresTime'
  )
  get needsRefresh(): boolean {
    if (this.isTiktok) {
      return this.needsTiktokRefresh;
    }

    if (this.isFacebook) {
      return isBlank(this.token) || !this.hasFacebookAnalyticsScope;
    }

    if (this.isInstagram) {
      if (this.auth.currentAccount?.rolloutIgWithFbLoginOauth) {
        if (this.auth.currentAccount?.rolloutLinkinbioLimitedInstagramPermissions) {
          return false;
        }

        if (!this.isProfessional || this.hasUnconnectedProfessional) {
          return true;
        }

        return this.hasProfessionalAccount && this.needsProfessionalTokenRefresh;
      }

      if (this.hasProfessionalAccount && this.needsProfessionalTokenRefresh) {
        return true;
      }
      return this.needsBasicDisplayTokenRefresh;
    }

    if (this.needsRefreshTokenRefresh) {
      return true;
    }
    return isBlank(this.token);
  }

  get hasExpiringToken(): boolean {
    return this.expiringToken || this.expiringBasicDisplayToken || this.expiringRefreshToken;
  }

  @computed('tokenExpiresTime')
  get expiringToken(): boolean {
    return this.expiringWithinDays(this.tokenExpiresTime);
  }

  @computed('basicDisplayTokenExpiresTime')
  get expiringBasicDisplayToken(): boolean {
    return this.expiringWithinDays(this.basicDisplayTokenExpiresTime);
  }

  @computed('authorizingFacebookTokenExpiresTime')
  get expiringUserToken(): boolean {
    return this.expiringWithinDays(this.authorizingFacebookTokenExpiresTime);
  }

  @computed('authorizingFacebookDataAccessExpiresTime')
  get expiringDataAccessToken(): boolean {
    return this.expiringWithinDays(this.authorizingFacebookDataAccessExpiresTime);
  }

  get expiringRefreshToken(): boolean {
    return this.expiringWithinDays(this.refreshTokenExpiresTime);
  }

  @computed(
    'isInstagram',
    'isTiktok',
    'token',
    'hasProfessionalAccount',
    'needsRefresh',
    'needsBasicDisplayTokenRefresh',
    'needsProfessionalTokenRefresh',
    'states',
    'instagramAccountType',
    'canConnectTiktokApi'
  )
  get socialProfileState(): ProfileAuthenticationState {
    if (this.isInstagram) {
      return this.#getInstagramState();
    }

    if (this.isTiktok) {
      return this.#getTiktokState();
    }

    return this.#getStateNonIg();
  }

  @computed('isTiktok', 'verifiedAt')
  get isTiktokUnverifiedAccount(): boolean {
    return this.isTiktok && !this.verifiedAt;
  }

  @computed('isTiktok', 'verifiedAt', 'isBusiness')
  get isTiktokLoginKitAccount(): boolean {
    return this.isTiktok && isPresent(this.verifiedAt) && !this.isBusiness;
  }

  @computed('isTiktok', 'verifiedAt', 'isBusiness')
  get isTiktokBusinessApiAccount(): boolean {
    return this.isTiktok && isPresent(this.verifiedAt) && this.isBusiness;
  }

  @computed('auth.currentAccount.canConnectTiktokApi')
  get canConnectTiktokApi(): boolean {
    return this.auth.currentAccount.canConnectTiktokApi;
  }

  @computed('isFacebook', 'facebookProfileType')
  get isDeprecatedFacebookProfile(): boolean {
    return this.isFacebook && this.facebookProfileType === 'USER';
  }

  get isMissingLinkedinPermissions(): boolean {
    return (
      this.credentialRefreshError?.includes(`You don't have enough permissions for this LinkedIn organization`) ?? false
    );
  }

  @computed('temporaryIndustry')
  get isPreviewingIndustry(): boolean {
    return Boolean(this.temporaryIndustry) && this.temporaryIndustry !== this.industry;
  }

  @computed('profileType', 'isInstagram')
  get cssHandle(): string | undefined {
    if (this.isInstagram) {
      return 'ig';
    }
    return this.profileType;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get platformAbbreviation(): string | null {
    if (this.isInstagram) {
      return 'ig';
    } else if (this.isTwitter) {
      return 'tw';
    } else if (this.isPinterest) {
      return 'pi';
    } else if (this.isFacebook) {
      return 'fb';
    } else if (this.isLinkedin) {
      return 'li';
    } else if (this.isTiktok) {
      return 'tk';
    } else if (this.isYoutube) {
      return 'yt';
    } else if (this.isThreads) {
      return 'th';
    }
    return null;
  }

  @computed('isInstagram', 'isProfessional', 'isCreator')
  get instagramProfileType(): InstagramProfileType | undefined {
    if (!this.isInstagram) {
      return undefined;
    }
    if (this.isProfessional) {
      return this.isCreator ? InstagramProfileTypes.Creator : InstagramProfileTypes.Business;
    }
    return InstagramProfileTypes.Personal;
  }

  @computed('platformAbbreviation', 'nickname')
  get segmentEventHandle(): string {
    return this.platformAbbreviation + ':' + this.nickname;
  }

  @computed('profileType')
  get postsLeftAccountProperty(): string {
    return `${this.profileType}PostsLeft`;
  }

  @computed(
    'postsLeftAccountProperty',
    'account.{instagramPostsLeft,twitterPostsLeft,facebookPostsLeft,pinterestPostsLeft,tiktokPostsLeft,linkedinPostsLeft}'
  )
  get postsLeft(): number {
    return get(this, 'account.' + this.postsLeftAccountProperty) as number;
  }

  @computed('postsLeft', 'hasUnlimitedPosts')
  get hasPostsLeft(): boolean {
    return this.hasUnlimitedPosts || this.postsLeft > 0;
  }

  @computed('postsLeft', 'hasUnlimitedPosts')
  get displayPostsLeft(): number {
    if (this.hasUnlimitedPosts) {
      return 999;
    }
    return this.postsLeft;
  }

  @computed('isPinterest', 'isBusiness', 'isLinkedin', 'isLinkedinPersonalProfile')
  get canPostVideo(): boolean {
    if (this.isLinkedin) {
      return !this.isLinkedinPersonalProfile;
    }
    return !this.isPinterest || this.isBusiness;
  }

  @computed('isTwitter')
  get canPostGif(): boolean {
    return this.isTwitter;
  }

  get canPostImage(): boolean {
    return !this.isYoutube;
  }

  @computed('isTwitter', 'isLinkedin', 'isFacebook', 'isThreads')
  get canTextOnly(): boolean {
    return this.isTwitter || this.isLinkedin || this.isFacebook || this.isThreads;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isTiktok', 'isLinkedin')
  get multiImageMaxCount(): MultiImageMaxCount {
    if (this.isInstagram || this.isFacebook) {
      return 10;
    } else if (this.isTwitter) {
      return 4;
    } else if (this.isLinkedin && !this.isLinkedinPersonalProfile) {
      return 20;
    } else if (this.isPinterest) {
      return 1;
    } else if (this.isTiktok) {
      return TIKTOK_MAX_IMAGES;
    } else if (this.isThreads) {
      return 20;
    }
    return 1;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get accountType(): SocialPlatformType {
    if (this.isInstagram) {
      return SocialPlatformType.Instagram;
    } else if (this.isTwitter) {
      return SocialPlatformType.Twitter;
    } else if (this.isPinterest) {
      return SocialPlatformType.Pinterest;
    } else if (this.isFacebook) {
      return SocialPlatformType.Facebook;
    } else if (this.isLinkedin) {
      return SocialPlatformType.LinkedIn;
    } else if (this.isTiktok) {
      return SocialPlatformType.TikTok;
    } else if (this.isYoutube) {
      return SocialPlatformType.Youtube;
    } else if (this.isThreads) {
      return SocialPlatformType.Threads;
    }
    return SocialPlatformType.Unknown;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get localizedAccountType(): string {
    if (this.isInstagram) {
      return this.intl.t('shared_words.instagram');
    } else if (this.isTwitter) {
      return this.intl.t('shared_words.twitter');
    } else if (this.isPinterest) {
      return this.intl.t('shared_words.pinterest');
    } else if (this.isFacebook) {
      return this.intl.t('shared_words.facebook');
    } else if (this.isLinkedin) {
      return this.intl.t('shared_words.linkedin');
    } else if (this.isTiktok) {
      return this.intl.t('shared_words.tiktok');
    } else if (this.isYoutube) {
      return this.intl.t('shared_words.youtube');
    }
    return '';
  }

  @computed('avatarUrl')
  get secureAvatarUrl(): string | undefined {
    if (!isNone(this.avatarUrl)) {
      return this.avatarUrl.replace('http://', 'https://');
    }
    return this.avatarUrl;
  }

  @computed('additionalPermissionScope.[]')
  get hasInstagramAutoPublishScope(): boolean {
    return Boolean(this.additionalPermissionScope?.includes('instagram_content_publish'));
  }

  @computed('hasProfessionalAccount', 'hasInstagramAutoPublishScope')
  get missingInstagramAutoPublishScope(): boolean {
    return this.hasProfessionalAccount && !this.hasInstagramAutoPublishScope;
  }

  @computed('isInstagram', 'basicDisplayToken', 'permissionScope.[]')
  get hasBasicDisplayUserMediaScope(): boolean {
    return (
      this.isInstagram && isPresent(this.basicDisplayToken) && Boolean(this.permissionScope?.includes('user_media'))
    );
  }

  @computed('isInstagram', 'basicDisplayToken', 'permissionScope.[]')
  get hasBasicDisplayUserProfileScope(): boolean {
    return (
      this.isInstagram && isPresent(this.basicDisplayToken) && Boolean(this.permissionScope?.includes('user_profile'))
    );
  }

  @computed('additionalPermissionScope.[]')
  get hasInstagramManageInsights(): boolean {
    if (!this.additionalPermissionScope) {
      return false;
    }
    return this.additionalPermissionScope.some((scope: string) => scope === 'instagram_manage_insights'); // check string values
  }

  /**
   * Returns whether or not the current social profile is a professional account without a professional account ID
   */
  @computed('isInstagram', 'isProfessional', 'hasProfessionalAccount')
  get hasUnconnectedProfessional(): boolean {
    return this.isInstagram && this.isProfessional && !this.hasProfessionalAccount;
  }

  @computed('id')
  get lastBoardKey(): string {
    return 'lastBoard:' + this.id;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get profileIconClass(): string | null {
    if (this.isInstagram) {
      return 'i--ig--w';
    } else if (this.isTwitter) {
      return 'i--twitter--w';
    } else if (this.isPinterest) {
      return 'i--pinterest--w';
    } else if (this.isFacebook) {
      return 'i--facebook--w';
    } else if (this.isLinkedin) {
      return 'i--linkedin--w';
    } else if (this.isTiktok) {
      return 'i--tiktok--w';
    } else if (this.isYoutube) {
      return 'i--youtube--w';
    } else if (this.isThreads) {
      return 'i--threads--w';
    }

    return null;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get profileIconColorClass(): string | null {
    if (this.isInstagram) {
      return 'i--ig';
    } else if (this.isTwitter) {
      return 'i--twitter';
    } else if (this.isPinterest) {
      return 'i--pinterest';
    } else if (this.isFacebook) {
      return 'i--facebook';
    } else if (this.isLinkedin) {
      return 'i--linkedin';
    } else if (this.isTiktok) {
      return 'i--tiktok';
    } else if (this.isYoutube) {
      return 'i--youtube';
    } else if (this.isThreads) {
      return 'i--threads';
    }

    return null;
  }

  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin', 'isTiktok', 'isYoutube')
  get avatarChildClass(): string | null {
    if (this.isInstagram) {
      return 'o--user__avatarChild--ig';
    } else if (this.isTwitter) {
      return 'o--user__avatarChild--twitter';
    } else if (this.isPinterest) {
      return 'o--user__avatarChild--pinterest';
    } else if (this.isFacebook) {
      return 'o--user__avatarChild--facebook';
    } else if (this.isLinkedin) {
      return 'o--user__avatarChild--linkedin';
    } else if (this.isTiktok) {
      return 'o--user__avatarChild--tiktok';
    } else if (this.isYoutube) {
      return 'o--user__avatarChild--youtube';
    } else if (this.isThreads) {
      return 'o--user__avatarChild--threads';
    }

    return null;
  }

  @computed('createdTime')
  get createdMoment(): Moment | undefined {
    if (isNumber(this.createdTime)) {
      return moment.unix(this.createdTime);
    }
    return;
  }

  @computed('isTiktokBusinessApiAccount', 'permissionScope.[]')
  get hasTiktokAutoPublishScope(): boolean {
    return this.isTiktokBusinessApiAccount && Boolean(this.permissionScope?.includes('video.publish'));
  }

  @computed('isTiktokBusinessApiAccount', 'permissionScope.[]')
  get hasTiktokAnalyticsScope(): boolean {
    return (
      this.isTiktokBusinessApiAccount &&
      Boolean(this.permissionScope?.includes('video.insights')) &&
      Boolean(this.permissionScope?.includes('video.list')) &&
      Boolean(this.permissionScope?.includes('video.publish'))
    );
  }

  @computed('isTiktokBusinessApiAccount', 'permissionScope.[]')
  get hasTiktokConversationScope(): boolean {
    return (
      this.isTiktokBusinessApiAccount &&
      Boolean(this.permissionScope?.includes('comment.list')) &&
      Boolean(this.permissionScope?.includes('comment.list.manage')) &&
      Boolean(this.permissionScope?.includes('video.list'))
    );
  }

  @computed('permissionScope.[]')
  get hasFacebookAnalyticsScope(): boolean {
    return Boolean(this.permissionScope?.includes('read_insights'));
  }

  /**
   * Returns whether the TikTok socialProfile needs to refresh the Business API token
   */
  @computed('businessRefreshTokenExpiresTime')
  get needsTiktokBusinessApiTokenRefresh(): boolean {
    return !this.businessRefreshTokenExpiresTime;
  }

  /**
   * Returns whether the socialProfile is a Tiktok profile whose business token has been invalidated
   * or the authorizing tiktok user token is missing
   */
  @computed('needsTiktokBusinessApiTokenRefresh', 'canConnectTiktokApi')
  get needsTiktokRefresh(): boolean {
    if ((this.isTiktokUnverifiedAccount && this.canConnectTiktokApi) || this.isTiktokLoginKitAccount) {
      return true;
    }

    return this.isTiktokBusinessApiAccount && this.needsTiktokBusinessApiTokenRefresh;
  }

  /**
   * Returns whether the Tiktok socialProfile has minimum permissions required to auto publish
   */
  @computed('isTiktokBusinessApiAccount', 'hasTiktokAutoPublishScope', 'needsTiktokRefresh')
  get canTiktokAutoPublish(): boolean {
    return this.isTiktokBusinessApiAccount && this.hasTiktokAutoPublishScope && !this.needsTiktokRefresh;
  }

  /**
   * Returns whether the socialProfile has the ability to toggle auto publish on
   * Note: This is not the same as canAutoPublish
   */
  @computed('isInstagram', 'isTiktok', 'canInstagramAutoPublish', 'canTiktokAutoPublish')
  get canSelectAutoPublish(): boolean {
    if (this.isTiktok) {
      return this.canTiktokAutoPublish;
    }

    if (this.isInstagram) {
      return this.canInstagramAutoPublish;
    }

    return false;
  }

  /**
   * Returns whether the socialProfile can schedule a post to auto publish
   * Note: This is not the same as canSelectAutoPublish
   */
  get canAutoPublish(): boolean {
    if (this.isTiktok || this.isInstagram) {
      return this.canSelectAutoPublish;
    }
    return !this.needsRefresh;
  }

  get canHighPerformingPost(): boolean {
    return this.isInstagram;
  }

  expiringWithinDays(time: Maybe<number>, days = 7): boolean {
    if (isNumber(time)) {
      const weekFromNow = timestamp() + convert.days(days).toSeconds();
      return !isBlank(time) && time < weekFromNow;
    }
    return false;
  }

  #states(): ProfileAuthenticationStates {
    if (!this._states || this._states?.isCreator !== this.isCreator) {
      this.set('_states', states(this));
    }
    return this._states;
  }

  #getInstagramState(): ProfileAuthenticationState {
    const personalState = this.#getIgPersonalState();
    const businessState = this.#getIgBusinessState();

    if (!businessState) {
      return personalState;
    }

    const businessStateWithPersonalAction = Object.assign({}, businessState, {
      action: personalState.action,
      status: personalState.status
    });
    // Note: LG-5851 decision to over ride enable business with refresh IG token
    if (!personalState.isValid && businessState.action.isConnectBusiness) {
      return businessStateWithPersonalAction;
    }

    if (businessState.isValid) {
      return businessStateWithPersonalAction;
    }
    return businessState;
  }

  #getStateNonIg(): ProfileAuthenticationState {
    if (this.isDeprecatedFacebookProfile) {
      return this.#states()['facebook-deprecated'](this.accountType);
    }
    if (this.hasExpiringToken) {
      return this.#states()['non-ig-expiring'](this.accountType);
    }

    return this.#states()[this.hasError ? 'non-ig-expired' : 'non-ig-valid'](this.accountType);
  }

  #getIgPersonalState(): ProfileAuthenticationState {
    if (this.auth.currentAccount?.rolloutIgWithFbLoginOauth && !this.isProfessional) {
      return this.#states()['ig-personal-deprecated'];
    }
    return this.#states()[this.needsBasicDisplayTokenRefresh ? 'ig-personal-expired' : 'ig-personal-valid'];
  }

  #getIgBusinessState(): ProfileAuthenticationState | null {
    if (this.auth.currentAccount?.rolloutIgWithFbLoginOauth) {
      if (!this.isProfessional) {
        return this.#states()['ig-personal-deprecated'];
      }

      if (this.hasUnconnectedProfessional || this.needsProfessionalTokenRefresh) {
        return this.#states()['ig-business-connected-deprecated'];
      }

      if (this.instagramBusinessTokenExpiring) {
        return this.#states()['ig-business-connected-expiring'];
      }

      const hasAllPermissions = this.instagramAuth.hasAllPermissions(this);
      return this.#states()[
        hasAllPermissions ? 'ig-business-connected-valid' : 'ig-business-connected-missing-permissions'
      ];
    }

    if (!this.isProfessional) {
      return null;
    }
    const { hasUnconnectedProfessional, needsProfessionalTokenRefresh, instagramBusinessTokenExpiring } = this;
    if (hasUnconnectedProfessional) {
      return this.#states()['ig-business-not-connected'];
    }

    if (needsProfessionalTokenRefresh) {
      return this.#states()['ig-business-connected-expired'];
    }

    if (instagramBusinessTokenExpiring) {
      return this.#states()['ig-business-connected-expiring'];
    }

    const hasAllPermissions = this.instagramBusiness.hasRequiredInstagramAdditionalScopes(this);
    return this.#states()[
      hasAllPermissions ? 'ig-business-connected-valid' : 'ig-business-connected-missing-permissions'
    ];
  }

  #getTiktokState(): ProfileAuthenticationState {
    if (this.isTiktokUnverifiedAccount && !this.canConnectTiktokApi) {
      return this.#states()['tiktok-unverified-valid'];
    }

    if (this.expiringWithinDays(this.businessRefreshTokenExpiresTime)) {
      return this.#states()['tiktok-expiring'];
    }

    if (this.needsTiktokRefresh) {
      return this.#states()['tiktok-api-refresh'];
    }

    return this.#states()['tiktok-api-valid'];
  }

  // @ts-expect-error Functionality is temporarily disabled
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  willExpireBeforeTimestamp(timestampToCheck: number, cutOff = convert.days(60).toSeconds()): boolean {
    // Note: Temporarily disabling this feature until pre-existing credential issues are resolved
    return false;

    // const tokenExpiryTime = this.get('tokenExpiresTime');

    // if (timestampToCheck > timestamp() + cutOff) {
    //   return false;
    // }

    // if (!isNumber(tokenExpiryTime) || !isNumber(timestampToCheck)) {
    //   return false;
    // }

    // return tokenExpiryTime < timestampToCheck;
  }

  async refreshProfile(): Promise<RefreshProfileResponse> {
    return await apiAction(this, {
      method: 'POST',
      path: 'refresh_profile'
    });
  }

  refreshAndReload = dropTask(async () => {
    try {
      if (isTesting()) {
        await this.refreshProfile;
      } else {
        const SPACING = 120_000;
        throttle(this, this.refreshProfile, SPACING);
      }
      await this.reload();
    } catch (error) {
      this.errorService.log(error);
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'social-profile': SocialProfileModel;
  }
}
