import ApplicationAdapter from './application';

export default class BrandProfileAdapter extends ApplicationAdapter {
  namespace = 'api/v2/social_listening/brand/profiles';
  serializer = 'brand-profile';

  urlForQuery(): string {
    return `${this.namespace}`;
  }
}
