import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { restartableTask, dropTask } from 'ember-concurrency';
import { isString } from 'lodash';

import { SOCIAL_PROFILE_CODES } from 'later/utils/constants';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type InstagramService from 'later/services/instagram';
import type LinkedinService from 'later/services/linkedin';
import type PinterestService from 'later/services/pinterest';
import type SegmentService from 'later/services/segment';
import type FacebookAuthService from 'later/services/social/facebook-auth';
import type InstagramAuthService from 'later/services/social/instagram-auth';
import type ThreadsService from 'later/services/threads';
import type TwitterService from 'later/services/twitter';
import type YoutubeService from 'later/services/youtube';
import type { OauthSocialProfileType } from 'later/utils/constants';
import type { UntypedService, Maybe } from 'shared/types';

interface AddSocialProfileArgs {
  selectedSocialProfileType: string;
  group: GroupModel;
  area?: string;
  redirectPath?: Maybe<string>;
  passedInSocialIdentityId?: string | null;
  shouldRedirect?: boolean;
  shouldRedirectToSettings?: boolean;
}

interface AddLinkedinProfileArgs {
  id: string;
  group: GroupModel;
  path: string;
}

enum TikTokStrategy {
  TransitionToPlans = 'transitionToPlans',
  TransitionToSettings = 'transitionToSettings',
  ConnectTikTokOauth = 'connectTikTokOauth'
}

type WritableProperties<T> = { -readonly [P in keyof T]: T[P] }; // this type doesn't prevent setting getters

type ServiceKeyType<T> = keyof T;

type ServicePropertyType<T> = T[keyof T];

type KeyOfSocialProfileCodes = keyof typeof SOCIAL_PROFILE_CODES;

export default class ConnectProfilesService extends Service {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare dialogManager: DialogManagerService;
  @service('social/instagram-auth') declare instagramAuth: InstagramAuthService;
  @service declare instagram: InstagramService;
  @service declare intl: IntlService;
  @service declare twitter: TwitterService;
  @service declare pinterest: PinterestService;
  @service declare linkedin: LinkedinService;
  @service declare tiktok: UntypedService;
  @service declare errors: ErrorsService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service('social/facebook-auth') declare facebookAuth: FacebookAuthService;
  @service declare segment: SegmentService;
  @service declare threads: ThreadsService;
  @service declare youtube: YoutubeService;

  @tracked selectedSocialIdentity: SocialIdentityModel | undefined;

  socialProfileNames = Object.keys(SOCIAL_PROFILE_CODES).reduce<Record<string, string>>(
    (keyValueSwapResult: Record<string, string>, key) => {
      keyValueSwapResult[SOCIAL_PROFILE_CODES[key as KeyOfSocialProfileCodes]] = key.toLowerCase();
      return keyValueSwapResult;
    },
    {}
  );

  get socialIdentityId(): string | undefined | null {
    return this.hasSocialProfile ? this.socialIdentity?.get('id') : null;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    if (isPresent(this.selectedSocialIdentity)) {
      return this.selectedSocialIdentity;
    }
    return this.hasSocialProfile ? this.auth.currentGroup.socialIdentities.firstObject : undefined;
  }

  get hasSocialProfile(): boolean {
    return isPresent(this.auth.currentGroup?.socialProfiles);
  }

  oAuthPath({
    socialProfileType,
    redirectGroupSlug,
    // Note: Using an 'id' instead of a 'slug' here will trigger a redirect
    redirectPath = `${redirectGroupSlug || this.auth.currentGroup?.slug}/schedule/calendar`
  }: {
    socialProfileType: OauthSocialProfileType;
    redirectGroupSlug?: string;
    redirectPath?: Maybe<string>;
  }): string {
    return `/oauth/${socialProfileType}?redirect_path=${redirectPath}`;
  }

  @action
  updateProperty(propertyName: ServiceKeyType<this>, value: ServicePropertyType<this>): void {
    (this as WritableProperties<typeof this>)[propertyName] = value;
  }

  addSocialProfile = restartableTask(
    async ({
      selectedSocialProfileType,
      group,
      redirectPath,
      area = '',
      shouldRedirectToSettings = false,
      passedInSocialIdentityId,
      shouldRedirect = true
    }: AddSocialProfileArgs) => {
      try {
        if (isPresent(area)) {
          this.segment.track(`attempted-connecting-${this.socialProfileNames[selectedSocialProfileType]}-account`, {
            area
          });
        }

        switch (selectedSocialProfileType) {
          case SOCIAL_PROFILE_CODES.INSTAGRAM:
            await this._connectInstagram.perform(
              group,
              shouldRedirectToSettings,
              redirectPath,
              passedInSocialIdentityId
            );
            break;
          case SOCIAL_PROFILE_CODES.TWITTER:
            await this.twitter.createTwitterWithSet(Number(this.socialIdentityId) ?? undefined, group, redirectPath);
            break;
          case SOCIAL_PROFILE_CODES.PINTEREST:
            await this.pinterest.createPinterestWithSet(this.socialIdentityId, group, redirectPath);
            break;
          case SOCIAL_PROFILE_CODES.FACEBOOK:
            await this._connectFacebook.perform(group.id, shouldRedirect, passedInSocialIdentityId);
            break;
          case SOCIAL_PROFILE_CODES.LINKEDIN:
            await this.linkedin.login(group, redirectPath);
            break;
          case SOCIAL_PROFILE_CODES.TIKTOK:
            await this.connectTikTok.perform(group, shouldRedirectToSettings, redirectPath);
            break;
          case SOCIAL_PROFILE_CODES.YOUTUBE:
            await this.youtube.createYoutubeWithSet(this.socialIdentityId, group);
            break;
          case SOCIAL_PROFILE_CODES.THREADS:
            this.threads.createThreadsWithSet(this.socialIdentityId, group, redirectPath);
            break;
          default:
            break;
        }
      } catch (error) {
        this.errors.log(error);
      }
    }
  );

  addLinkedinProfile = dropTask(async ({ id, group, path }: AddLinkedinProfileArgs) => {
    try {
      const socialIdentityId = this.cache.retrieve('linkedin_selected_social_identity_id');

      let socialIdentity: Maybe<SocialIdentityModel>;
      if (socialIdentityId && isString(socialIdentityId) && socialIdentityId !== '0') {
        socialIdentity = await this.store.findRecord('social-identity', socialIdentityId);
      } else {
        socialIdentity = undefined;
      }

      await this.linkedin.addProfile(id, group, socialIdentity);
      this.cache.remove('linkedin_selected_social_identity_id');
      this.router.transitionTo(path, group, { queryParams: { show_linkedin_selector: false } });
    } catch (error) {
      this.errors.log(error);
    }
  });

  _connectInstagram = dropTask(
    async (
      group: GroupModel,
      shouldRedirectToSettings: boolean,
      redirectPath?: string | null,
      passedInSocialIdentityId?: string | null
    ) => {
      const didConfirm = await this.dialogManager.instagramConnect({
        message: this.intl.t('shared_phrases.add_instagram'),
        description: this.intl.t('credentials.ig_facebook_connect_modal.connect_info'),
        learnMoreLink:
          'https://help.later.com/hc/en-us/articles/1500001601602-Why-Does-Later-Ask-Me-to-Connect-Instagram-to-a-Facebook-Page'
      });
      if (!didConfirm) return;

      if (this.auth.currentAccount?.rolloutIgWithFbLoginOauth) {
        await this.instagramAuth.authenticateWithFacebookGraphApi.perform({
          socialIdentityId: passedInSocialIdentityId || undefined
        });
      } else {
        await this.connectInstagramPersonal.perform(
          group,
          shouldRedirectToSettings,
          redirectPath,
          passedInSocialIdentityId
        );
      }
    }
  );

  _connectFacebook = dropTask(
    async (groupId: string, shouldRedirect: boolean, passedInSocialIdentityId?: string | null) => {
      const group = this.store.peekRecord('group', groupId);

      if (!group) {
        throw new Error(`Unable to find Group record with provided ID: ${groupId}`);
      }

      if (shouldRedirect) {
        this.router.transitionTo('cluster.schedule.calendar', group.slug);
      }

      await this.facebookAuth.authenticate.perform({
        socialIdentityId: passedInSocialIdentityId || undefined
      });
    }
  );

  connectInstagramPersonal = dropTask(
    async (
      group: GroupModel,
      shouldRedirectToSettings: boolean,
      redirectPath?: string | null,
      passedInSocialIdentityId?: string | null
    ) => {
      const socialIdentityId = passedInSocialIdentityId ? passedInSocialIdentityId : this.socialIdentityId;

      if (shouldRedirectToSettings) {
        this.router.transitionTo('account.groups.group.social_profiles.new', group.id);
      } else {
        await this.instagram.createInstagramWithSet(socialIdentityId, group, redirectPath);
      }
    }
  );

  connectTikTok = dropTask(
    async (group: GroupModel, shouldRedirectToSettings: boolean, redirectPath?: string | null) => {
      const tikTokStrategy = this.#decideStrategyForTikTok(shouldRedirectToSettings);

      switch (tikTokStrategy) {
        case TikTokStrategy.TransitionToSettings:
          this.router.transitionTo('account.groups.group.social_profiles.new', group.id);
          break;
        case TikTokStrategy.TransitionToPlans:
          this.router.transitionTo('plans.migrate');
          break;
        case TikTokStrategy.ConnectTikTokOauth:
          await this.tiktok.createTiktokWithSet(this.socialIdentityId, group, redirectPath);
          break;
        default:
          break;
      }
    }
  );

  #decideStrategyForTikTok(shouldRedirectToSettings: boolean): TikTokStrategy {
    const { isOnLegacyPlan } = this.auth.currentAccount || {};

    if (isOnLegacyPlan) {
      return TikTokStrategy.TransitionToPlans;
    }

    if (shouldRedirectToSettings) {
      return TikTokStrategy.TransitionToSettings;
    }

    return TikTokStrategy.ConnectTikTokOauth;
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/connect-profiles': ConnectProfilesService;
  }
}
