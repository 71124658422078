import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Transition } from 'shared/types';

export default class SurveysRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;
  @service declare store: Store;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    const identity = this.group?.socialIdentities.firstObject;
    return identity;
  }

  async redirect(_model: unknown, transition: Transition): Promise<void> {
    if (!this.groupSlug || !this.socialIdentity) {
      this.router.transitionTo('/');
      return;
    }

    const { survey_slug } = transition.to.params;
    const { queryParams } = transition.to;
    const influencerId = queryParams.influencer_id;

    let targetGroupSlug = this.groupSlug;
    let targetSocialIdentityId = Number(this.socialIdentity.id);

    if (influencerId) {
      const influencer = await this.store.findRecord('influencer', Number(influencerId));

      if (influencer) {
        const socialIdentity = await influencer?.get('socialIdentity');
        const group = await socialIdentity?.get('group');
        targetGroupSlug = group?.slug ?? targetGroupSlug;
        targetSocialIdentityId = Number(socialIdentity?.id ?? targetSocialIdentityId);
      }
    }

    this.router.transitionTo(
      'cluster.partnerships.profile.surveys.survey',
      targetGroupSlug,
      targetSocialIdentityId,
      survey_slug!,
      { queryParams }
    );
  }
}
