import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import jstz from 'jstz';
import Pica from 'pica';

import { ImageSizeError, MediaSizeError, MediaUploadError, UpgradeMediaSizeError } from 'later/errors/index';
import {
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
  MAX_IMG_DIMENSIONS,
  MAX_VIDEO_SIZE_WITHOUT_AUTO_SCALING
} from 'later/utils/constants';
import { isVideo, isImage } from 'later/utils/media-item-upload/check-file-type';
import { convert } from 'later/utils/time-format';
import { getImageFromFile } from 'shared/utils/file';

import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type MediaItemModel from 'later/models/media-item';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type SeamlessCheckoutManagerService from 'later/services/seamless-checkout-manager';
import type SubscriptionsService from 'later/services/subscriptions';
import type UploadBusService from 'later/services/upload-bus';
import type { EmberExexCompat } from 'shared/errors/ember-exex-compat';
import type { UntypedService } from 'shared/types';
import type { ChooserFile } from 'shared/types/dropbox';

export type FileType = ChooserFile | Blob | File;

export default class MediaItemUploadService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare localStorageManager: LocalStorageManagerService;
  @service declare mediaUpload: UntypedService;
  @service declare seamlessCheckoutManager: SeamlessCheckoutManagerService;
  @service declare store: StoreService;
  @service declare subscriptions: SubscriptionsService;
  @service declare uploadBus: UploadBusService;

  limits = {
    image: {
      max_mb: MAX_IMAGE_SIZE / 1024 / 1024,
      media_type: 'image'
    },
    video: {
      max_mb: MAX_VIDEO_SIZE / 1024 / 1024,
      media_type: 'video'
    }
  } as const;

  getMediaError(file: FileType): EmberExexCompat | void {
    if (!isPresent(file)) {
      return new MediaUploadError({ message: this.intl.t('alerts.media_items.new.jpg_or_png') });
    }

    if (isVideo(file)) {
      const videoError = this.#getVideoError(file);
      if (videoError) {
        return videoError;
      }
    } else if (isImage(file)) {
      const imageError = this.#getImageError(file);
      if (imageError) {
        return imageError;
      }
    } else {
      return new MediaUploadError({ message: this.intl.t('alerts.media_items.new.jpg_or_png') });
    }
    return;
  }

  /**
   * Shows the upgrade modal by navigating to the upgrade route
   * function is used in lib/shared/addon/errors/media-library.ts
   * but not findable by searching for service instances i.e mediaItemUpload.showUpgradeModal
   */
  showUpgradeModal(feature: string, location: string): void {
    if (this.subscriptions.isMobileSubscription) {
      this.subscriptions.openMobileRedirectModal();
    }
    // Note: used by UpgradeMediaSizeError for the video size limit
    this.seamlessCheckoutManager.upgrade({ feature, location });
  }

  /**
   * Uploads a media item (file) to AWS S3 via the uploadBus service.
   * Calls createMediaItem for videos, and processMedia for images.
   * Rejects if incorrect permissions, dimensions, or file type are given.
   */
  async uploadMediaItem(file: Blob | File, mediaItemValues = {}): Promise<void> {
    const mediaError = this.getMediaError(file);
    if (mediaError) {
      this.#handleMediaError(mediaError);
      return;
    }

    try {
      if (isVideo(file)) {
        await this.createMediaItem(file, {}, mediaItemValues);
      } else {
        await this.processMedia(file, mediaItemValues);
      }
    } catch (error) {
      this.alerts.warning(error);
    }
  }

  async #addUploadToQueue(file: Blob | File, mediaItem: MediaItemModel): Promise<void> {
    try {
      await this.uploadBus.addUploadToQueue(
        file,
        mediaItem,
        () => {
          this.alerts.success(this.intl.t('alerts.media_items.new.media_uploaded.message'), {
            title: this.intl.t('alerts.media_items.new.media_uploaded.title'),
            timeout: 5000
          });
        },
        () => {
          this.alerts.alert(this.intl.t('alerts.calendar.s3_upload_failed.message'), {
            title: this.intl.t('alerts.calendar.s3_upload_failed.title')
          });
        }
      );
    } catch (error) {
      this.errors.log('addUploadToQueue error', error);
    }
  }

  /**
   * Takes a media item (file), sets default timestamp, group and dimensions,
   * creates a media item record in the store, and uploads to AWS S3 via the uploadBus service.
   * Rejects if upload, or media item creation fails.
   */
  async createMediaItem(
    file: Blob | File,
    { width, height }: { width?: number; height?: number },
    mediaItemValues: object
  ): Promise<MediaItemModel | void> {
    const offsetInSeconds = convert.hour().toSeconds();
    const currentTime = convert.milliseconds(Date.now()).toSeconds() + offsetInSeconds;
    const mediaItem = this.#getMediaItem(currentTime, width, height, mediaItemValues);

    try {
      await this.#addUploadToQueue(file, mediaItem);

      const newItemCount = this.auth.currentGroup.mediaItemCount + 1;
      this.auth.currentGroup.set('mediaItemCount', newItemCount);

      return mediaItem;
    } catch (error) {
      if (!mediaItem.get('isDeleted')) {
        mediaItem.destroyRecord();
      }

      if (error.name === 'NetworkingError' || error.name === 'TimeoutError') {
        this.alerts.warning(this.intl.t('alerts.calendar.network_error'));
      } else if (error.name === 'RequestTimeTooSkewed') {
        const timezone = jstz.determine();
        this.localStorageManager.setItem('is_incorrect_system_time', true);
        this.alerts.warning(this.intl.t('alerts.calendar.time_skewed', { time_zone: timezone.name() }));
      } else if (error.isAdapterError) {
        this.alerts.warning(this.intl.t('alerts.calendar.media_item_create_failed', { reason: error.message }));
      } else {
        this.alerts.warning(this.intl.t('alerts.calendar.upload_failed', { reason: error.message }));
      }
    }
  }

  #displayVideoResizeWarning(fileSize: number): void {
    if (fileSize >= MAX_VIDEO_SIZE_WITHOUT_AUTO_SCALING) {
      this.alerts.warning(this.intl.t('alerts.media_items.new.video_resize_warning.message'), {
        title: this.intl.t('alerts.media_items.new.video_resize_warning.title')
      });
    }
  }

  #getImageError(file: FileType): EmberExexCompat | void {
    const accountMaxSize = this.mediaUpload.maxImageSize;

    const mediaSize = this.#getMediaSize(file);
    if (mediaSize > MAX_IMAGE_SIZE) {
      return new MediaSizeError({
        message: this.intl.t('alerts.media_items.new.file_too_big', this.limits.image)
      });
    } else if (mediaSize > accountMaxSize) {
      let message;
      if (this.subscriptions.isMobileSubscription) {
        message = this.subscriptions.isEligibleForTrial
          ? this.intl.t('alerts.media_items.new.image_limit_over.message_mobile_trial', {
              mb: this.mediaUpload.maxImageSizeMB,
              platform: this.subscriptions.platform
            })
          : this.intl.t('alerts.media_items.new.image_limit_over.message_mobile', {
              mb: this.mediaUpload.maxImageSizeMB,
              platform: this.subscriptions.platform
            });
      } else {
        message = this.intl.t('alerts.media_items.new.image_limit_over.message', {
          mb: this.mediaUpload.maxImageSizeMB
        });
      }
      return new ImageSizeError({ message });
    } else if ('type' in file && file.type === 'image/webp') {
      return new MediaUploadError(this.intl.t('alerts.media_items.new.webp_support'));
    } else if ('type' in file && file.type === 'image/tiff') {
      return new MediaUploadError(this.intl.t('alerts.media_items.new.tiff_support'));
    } else if ('type' in file && file.type === 'image/heic') {
      return new MediaUploadError(this.intl.t('alerts.media_items.new.heic_support'));
    }
  }

  #getMediaItem(
    createdTime: number,
    width: number | undefined,
    height: number | undefined,
    mediaItemValues: object
  ): MediaItemModel {
    const defaults = {
      createdTime,
      group: this.auth.currentGroup,
      width,
      height
    };
    const mergedOptions = { ...defaults, ...mediaItemValues };
    const mediaItem = this.store.createRecord('media-item', mergedOptions);

    return mediaItem;
  }

  #getVideoError(file: FileType): EmberExexCompat | void {
    const accountMaxSize = this.mediaUpload.maxVideoSize;
    const mediaSize = this.#getMediaSize(file);
    if (mediaSize > MAX_VIDEO_SIZE) {
      return new MediaSizeError({
        message: this.intl.t('alerts.media_items.new.file_too_big', this.limits.video)
      });
    } else if (mediaSize > accountMaxSize) {
      return new UpgradeMediaSizeError();
    } else if ('type' in file && file.type === 'video/x-flv') {
      return new MediaUploadError({ message: this.intl.t('alerts.media_items.new.flv_support') });
    }

    this.#displayVideoResizeWarning(mediaSize);
  }

  #getMediaSize(file: FileType): number {
    if ('size' in file) {
      return file.size;
    }
    return file.bytes;
  }

  #handleMediaError(mediaError: EmberExexCompat): void {
    if (mediaError instanceof MediaUploadError) {
      mediaError.resolve.call(this, mediaError);
    } else {
      this.errors.show(mediaError);
    }
  }

  /**
   * Takes a media item (file), computes dimensions, resizes and uploads to AWS S3 via createMediaItem,
   * which calls the uploadBus service. Rejects if file type is unsupported.
   */
  async processMedia(file: Blob | File, mediaItemValues: object): Promise<void> {
    const img = await getImageFromFile(file);
    const { width, height } = img;
    const name = file instanceof File ? file.name : '';
    const type = file.type || 'image/jpeg';

    if (width > MAX_IMG_DIMENSIONS || height > MAX_IMG_DIMENSIONS) {
      try {
        const blob = await this.#resize(img, type);
        const resizedFile = new File([blob], name);
        await this.createMediaItem(resizedFile, { width, height }, mediaItemValues);
      } catch ({ message }) {
        await this.createMediaItem(file, { width, height }, mediaItemValues);
        this.errors.log('Media resize failed', { message });
      }
    } else {
      await this.createMediaItem(file, { width, height }, mediaItemValues);
    }
  }

  async #resize(img: HTMLImageElement, type: string): Promise<Blob> {
    const canvas = document.createElement('canvas');
    const { width, height } = img;
    if (width > height) {
      canvas.width = MAX_IMG_DIMENSIONS;
      canvas.height = canvas.width * (height / width);
    } else {
      canvas.height = MAX_IMG_DIMENSIONS;
      canvas.width = canvas.height * (width / height);
    }
    const pica = Pica();
    const resized = await pica.resize(img, canvas);
    const blob = await pica.toBlob(resized, type, 0.95);
    this.alerts.warning(
      this.intl.t('alerts.media_items.new.image_large.message', {
        dimensions: `${width} x ${height}`
      }),
      {
        title: this.intl.t('alerts.media_items.new.image_large.title')
      }
    );
    return blob;
  }
}

declare module '@ember/service' {
  interface Registry {
    'media-item-upload': MediaItemUploadService;
  }
}
