/**
 * @module Services
 */

import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { reads } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty, typeOf, isPresent } from '@ember/utils';
import { task, enqueueTask, restartableTask } from 'ember-concurrency';
import RSVP from 'rsvp';

import 'later/types/typedef';
import { filterTimeSeries } from 'later/utils/array-filters';
import createFacebookPostFromDynamo from 'shared/utils/formatters/fb-post-from-dynamo';
import createIgPostFromDynamo from 'shared/utils/formatters/ig-post-from-dynamo';
import createIgReelFromDynamo from 'shared/utils/formatters/ig-reel-from-dynamo';
import createPinFromDynamo from 'shared/utils/formatters/pin-from-dynamo';
import createThreadsPostFromDynamo from 'shared/utils/formatters/th-post-from-dynamo';
import createTiktokPostFromDynamo from 'shared/utils/formatters/tiktok-post-from-dynamo';
import createTweetFromDynamo from 'shared/utils/formatters/tweet-from-dynamo';
import getHashtags from 'shared/utils/get-hashtags';

/** Adds unique dynamo media attributes to IgPost for graph API responses.
 *
 * @method addDynamoAttrsToMedia
 * @param {IgPost} Raw instagram post
 * @param {Array} Array of dynamo posts
 *
 * @return {IgPost} Instagram post with unique dynamo post attrs
 * @protected
 */

function addDynamoAttrsToMedia(post, dynamoPosts) {
  // Note: LF-1454 - Dynamo media table ids can be ig_id or fb_id (id).
  // We must check which entry contains a follower count and use that one.
  const dynamoPostWithId = dynamoPosts.findBy('id', post.id);
  const dynamoPostWithIgId = dynamoPosts.findBy('id', post.igId);
  const dynamoPost = dynamoPostWithId?.followersOnPost ? dynamoPostWithId : dynamoPostWithIgId;

  if (isPresent(dynamoPost)) {
    // Make sure we use the dynamo ID as the post ID for any lifespan graphs if it exists
    post.id = dynamoPost.id;

    if (dynamoPost.followersOnPost) {
      post.followersOnPost = dynamoPost.followersOnPost;
    }

    if (dynamoPost.videoViews) {
      post.videoViews = dynamoPost.videoViews;
    }

    post.imageUrl = dynamoPost.imageUrl ?? post.standardResUrl;
  }

  return post;
}

/** Adds linkinbio id to media item
 *
 * @method addLinkinbioIdToMedia
 * @param {IgPost} Raw instagram post
 * @param {Array} Array of linkinbio posts
 *
 * @return {IgPost} Instagram post with linkinbioPostId attached if available
 * @protected
 */

function addLinkinbioIdToMedia(post, linkinbioPosts) {
  const linkinbioPost = linkinbioPosts.findBy('media_id', post.id);

  if (linkinbioPost) {
    post.linkinbioPostId = linkinbioPost.id;
  }
  return post;
}

/**
 * @class AnalyticsDynamoService
 * @extends Service
 */
export default class MediaAnalyticsService extends Service {
  @service('analytics/instagram-analytics') instagramAnalytics;
  @service('analytics/dynamo-api') dynamoApi;
  @service('analytics/keen-analytics') keenAnalytics;
  @service('analytics/later-analytics') laterAnalytics;
  @service analytics;
  @service('analytics/analytics-access') analyticsAccess;
  @service instagram;
  @service pinterest;
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service errors;
  @service store;
  @service('analytics/formatters/table/posts') formattersIGPostTable;
  @service('analytics/formatters/table/reels') formattersIGReelTable;
  @service('analytics/formatters/table/tiktok-posts') formattersTiktokPostTable;
  @service('analytics/formatters/table/facebook-posts') formattersFacebookPostTable;
  @service('analytics/formatters/table/threads-posts') formattersThreadsPostsTable;

  @reads('analytics.socialProfile') socialProfile;

  /**
   * Whether the current social profile is of type professional
   *
   * @property isProfessional
   * @type {Boolean}
   */
  @reads('analytics.isProfessional')
  isProfessional;

  /**
   * Default start date for data calls in this service
   *
   * @property startDate
   * @type {(Moment|Date)}
   * @default
   */
  get startDate() {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   *
   * @property endDate
   * @type {(Moment|Date)}
   * @default
   */
  get endDate() {
    return this.helpersAnalytics.createMomentInTz();
  }

  /**
   * Initializes this service
   *
   * @method constructor
   */
  constructor(...args) {
    super(...args);

    this.defaultData = Object.assign({}, this.dynamoApi.data);
  }

  /**
   * Gets all media.
   *
   * @property getMedia
   *
   * @returns {RawMedia[]} All media.
   */
  getMedia = enqueueTask(async () => {
    return await this.dynamoApi.getMedia.linked().perform();
  });

  /**
   * Fetches a pin for the given mediaId.
   *
   * @property getPinById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {FormattedDynamoPin | null} The pin associated with the given mediaId
   */
  getPinById = task(async (mediaId, forceRefresh = false) => {
    const pins = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);

    if (pins && pins.length) {
      const pin = pins.find((pin) => pin.id === mediaId);

      if (!pin) {
        return null;
      }

      const isPinProcessed = Object.keys(pin).includes('pinnerId');
      return isPinProcessed ? pin : createPinFromDynamo(pin);
    }

    return null;
  });

  /**
   * Fetches tiktok post for the given mediaId.
   *
   * @property getTiktokPostById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {FormattedDynamoTiktokPost | null} The media associated with the given mediaId
   */
  getTiktokPostById = task(async (mediaId, forceRefresh = false) => {
    const tiktokPosts = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);
    if (isPresent(tiktokPosts)) {
      const tiktokPost = tiktokPosts.find((tiktokPost) => tiktokPost.id === mediaId);
      return tiktokPost ? createTiktokPostFromDynamo(tiktokPost) : null;
    }
    return null;
  });

  /**
   * Fetches threads post for the given mediaId.
   *
   * @property getThreadsPostById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {FormattedDynamoTiktokPost | null} The media associated with the given mediaId
   */
  getThreadsPostById = task(async (mediaId, forceRefresh = false) => {
    const threadsPosts = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);
    if (isPresent(threadsPosts)) {
      const threadsPost = threadsPosts.find((tp) => tp.id === mediaId);
      return threadsPost ? createThreadsPostFromDynamo(threadsPost) : null;
    }
    return null;
  });

  /**
   * Fetches tiktok post for the given mediaId.
   *
   * @property getFacebookPostById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {DynamoFbPost | null} The media associated with the given mediaId
   */
  getFacebookPostById = task(async (mediaId, forceRefresh = false) => {
    const facebookPosts = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);
    if (isPresent(facebookPosts)) {
      const facebookPost = facebookPosts.find((facebookPost) => facebookPost.id === mediaId);
      return facebookPost ? createFacebookPostFromDynamo(facebookPost) : null;
    }
    return null;
  });

  /**
   * Fetches tweet for the given mediaId.
   *
   * @property getTweetById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {FormattedDynamoTweet | null} The media associated with the given mediaId
   */
  getTweetById = task(async (mediaId, forceRefresh = false) => {
    const tweets = await this.dynamoApi.getPostsByIds.linked().perform([mediaId], forceRefresh);
    if (tweets && tweets.length) {
      const tweet = tweets.find((tweet) => tweet.id === mediaId);
      return tweet ? createTweetFromDynamo(tweet) : null;
    }
    return null;
  });

  /**
   * Fetches analytics lite ig post for the given mediaId.
   *
   * @property getAnalyticsLitePostById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {IgPost | null} The media associated with the given mediaId
   */
  getAnalyticsLitePostById = task(async (mediaId, forceRefresh = false) => {
    const igPostRequest = this.instagram.fetchMedia(this.socialProfile, mediaId);
    const dynamoPostRequest = this.getPostById.linked().perform(mediaId, forceRefresh);

    const igPost = await igPostRequest;
    const dynamoPost = await dynamoPostRequest;

    if (!igPost) {
      return null;
    }

    return addDynamoAttrsToMedia(igPost, [dynamoPost]);
  });

  /**
   * Fetches ig post for the given mediaId.
   *
   * @property getPostById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {IgPost | null}} The media associated with the given mediaId
   */
  getPostById = task(async (mediaId, forceRefresh = false) => {
    const posts = await this.getPostsByIds.linked().perform([mediaId], forceRefresh);

    if (posts && posts.length) {
      return posts.findBy('id', mediaId);
    }

    return null;
  });

  /**
   * Fetches media for the given array of media IDs.
   *
   * @property getPostsByIds
   * @param {Array.<Number>} ids Array of Media IDs
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Promise<DynamoIgPost[]>}
   */
  getPostsByIds = task(async (ids, forceRefresh = false) => {
    let posts = await this.dynamoApi.getPostsByIds.linked().perform(ids, forceRefresh);
    posts = await this._appendPostModelToPostData.perform(posts);

    if (!posts) {
      return undefined;
    }

    return posts.map((post) => createIgPostFromDynamo(post, this.socialProfile));
  });

  /**
   * Fetches ig reel for the given mediaId.
   *
   * @property getReelById
   * @param {Number} mediaId Id of the media
   * @param {Boolean} [forceRefresh=false]
   *
   * @returns {DynamoIgReel | null} The media associated with the given mediaId
   */
  getReelById = task(async (mediaId, forceRefresh = false) => {
    const reels = await this.getReelsByIds.linked().perform([mediaId], forceRefresh);

    if (reels && reels.length) {
      return reels.findBy('id', mediaId);
    }

    return null;
  });

  /**
   * Fetches reels for the given array of media IDs.
   *
   * @property getReelsByIds
   * @param {Array.<Number>} ids Array of Media IDs
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Promise<DynamoIgReel[]>}
   */
  getReelsByIds = task(async (ids, forceRefresh = false) => {
    let reels = await this.dynamoApi.getPostsByIds.linked().perform(ids, forceRefresh);
    reels = await this._appendPostModelToPostData.perform(reels);

    if (!reels) {
      return undefined;
    }

    return reels.map((reel) => createIgReelFromDynamo(reel, this.socialProfile));
  });

  /**
   * Returns Pins and Pinterest boards
   *
   * @property getFormattedPins
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<Pin>} Formatted pins and Pinterest boards
   */
  getFormattedPins = enqueueTask(async (forceRefresh = false) => {
    const result = await this.dynamoApi.getMedia.linked().perform(forceRefresh);

    if (!result || !result.media) {
      return null;
    }

    try {
      const { boards: fetchedBoards } = await this.pinterest.fetchBoards.perform(this.socialProfile);
      if (!fetchedBoards) {
        this.socialProfile.token = null;
      }

      const media = result.media
        .map((pin) => {
          const formattedPinWithClicks = this.helpersAnalytics.formatShortLinkClicksOnMedia(pin);
          return createPinFromDynamo(formattedPinWithClicks);
        })
        .reverse();

      const resultBoards = result.boards;

      const resultBoardIds = resultBoards.map((board) => board.id);
      const uniqueFetchedBoards = fetchedBoards.filter((board) => !resultBoardIds.includes(board.id));

      const boards = [...uniqueFetchedBoards, ...resultBoards];

      return { media, boards };
    } catch (error) {
      this.socialProfile.token = null;

      this.errors.log(new Error('Unable to fetch Pinterest Boards'), error);
      throw error;
    }
  });

  /**
   * Returns Tweets
   *
   * @property getFormattedTweets
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<Tweet>} Tweets in the given time range.
   */
  getFormattedTweets = enqueueTask(async (forceRefresh = false) => {
    try {
      const result = await this.dynamoApi.getMedia.linked().perform(forceRefresh);

      if (!result || typeOf(result) !== 'array') {
        return null;
      }

      return result.map((tweet) => {
        const formattedTweetWithClicks = this.helpersAnalytics.formatShortLinkClicksOnMedia(tweet);
        return createTweetFromDynamo(formattedTweetWithClicks);
      });
    } catch (error) {
      this.errors.log(error);
    }
  });

  /**
   * Returns Instagram posts for analytics lite users.
   *
   * @property getAnalyticsLitePosts
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<IgPost>} Instagram posts in the given time range.
   */
  getAnalyticsLitePosts = task(async (forceRefresh = false) => {
    const posts = await this.instagramAnalytics.getPosts.linked().perform(forceRefresh);
    let linkinbioPosts = null;
    let linkinbioClicks = null;

    try {
      linkinbioPosts = await this.laterAnalytics.getLinkinbioAnalyticsPosts.linked().perform(forceRefresh);
      linkinbioClicks = await this.keenAnalytics.getMediaTotalClicks
        .linked()
        .perform(this.startDate, this.endDate, forceRefresh);
    } catch (error) {
      if (this.analytics.hasLinkinbioEnabled) {
        this._handleKeenError(error.message, this.socialProfile, 'lib-clicks-daily-linkinbio-post-embedded');
      }
    }

    if (!posts || isEmpty(posts)) {
      return null;
    }

    // Note: LF-1454 - Dynamo media table ids can be ig_id or fb_id (id)
    // so we have to temporarily fetch both and pick the present one
    // until the legacy ig api is removed
    const mediaIds = [...posts.mapBy('id'), ...posts.mapBy('igId')];
    const dynamoPosts = await this.getPostsByIds.linked().perform(mediaIds, forceRefresh);

    if (!dynamoPosts) {
      return null;
    }

    const formattedPosts = posts
      .map((post) => this._addHashtagsToMedia(post))
      .map((post) => addDynamoAttrsToMedia(post, dynamoPosts))
      .map((post) => addLinkinbioIdToMedia(post, linkinbioPosts));

    const reformattedPosts = linkinbioClicks
      ? this.helpersAnalytics.addLinkinbioClicksToPosts(formattedPosts, linkinbioClicks)
      : formattedPosts;

    return { data: reformattedPosts };
  });

  /**
   * Returns Instagram posts for analytics standard users.
   *
   * @property getAnalyticsStandardPosts
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<IgPost>} Instagram posts in the given time range.
   */
  getAnalyticsStandardPosts = restartableTask(
    async (startDate, endDate, limit, cursor = null, paginatedData = false, forceRefresh = false) => {
      const promises = {
        analyticsStandardPosts: paginatedData
          ? this.dynamoApi.getPaginatedMedia.linked().perform(startDate, endDate, limit, cursor)
          : this.dynamoApi.getMedia.linked().perform(forceRefresh),
        linkinbioPosts: this.laterAnalytics.getLinkinbioAnalyticsPosts.linked().perform(forceRefresh),
        linkinbioClicks: this.keenAnalytics.getMediaTotalClicks.linked().perform(startDate, endDate, forceRefresh)
      };

      return await RSVP.hashSettled(promises).then(
        async ({ analyticsStandardPosts, linkinbioPosts, linkinbioClicks }) => {
          let analyticsStandardPostsData = [];
          const { cursors } = analyticsStandardPosts.value;

          if (analyticsStandardPosts.state === 'fulfilled') {
            analyticsStandardPostsData = !Array.isArray(analyticsStandardPosts.value)
              ? analyticsStandardPosts.value.data
              : analyticsStandardPosts.value;
          } else if (analyticsStandardPosts.state === 'rejected') {
            return paginatedData
              ? this._addCursorsToData(analyticsStandardPostsData, cursors)
              : analyticsStandardPostsData;
          }

          analyticsStandardPostsData = await this._appendPostModelToPostData.perform(analyticsStandardPostsData);
          const reformattedPosts = this._filterAndReformatPosts(analyticsStandardPostsData, startDate, endDate);

          if (linkinbioClicks.state === 'fulfilled' && linkinbioPosts.state === 'fulfilled') {
            return paginatedData
              ? this._addCursorsToData(
                  this.helpersAnalytics.addLinkinbioClicksToPosts(reformattedPosts, linkinbioClicks.value),
                  cursors
                )
              : this.helpersAnalytics.addLinkinbioClicksToPosts(reformattedPosts, linkinbioClicks.value);
          }
          if (this.analytics.hasLinkinbioEnabled) {
            this._handleKeenError(linkinbioClicks.reason, this.socialProfile, 'lib-total-clicks-linkinbio-post');
          }

          return paginatedData ? this._addCursorsToData(reformattedPosts, cursors) : reformattedPosts;
        }
      );
    }
  );

  /**
   * Returns Instagram reels for analytics standard users.
   *
   * @property getInstagramReels
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(String)} [cursor=null] Current cursor position or null
   * @param {Boolean} [paginatedData=false]
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<DynamoIgReel>} Instagram reels in the given time range.
   */
  getInstagramReels = restartableTask(async (startDate, endDate, limit, cursor = null, paginatedData = false) => {
    const promises = {
      instagramReels: this.dynamoApi.getPaginatedReels.linked().perform(startDate, endDate, limit, cursor)
    };

    return await RSVP.hashSettled(promises).then(async ({ instagramReels }) => {
      let instagramReelsData = [];
      const { cursors } = instagramReels.value;

      if (instagramReels.state === 'fulfilled') {
        instagramReelsData = !Array.isArray(instagramReels.value) ? instagramReels.value.data : instagramReels.value;
      } else if (instagramReels.state === 'rejected') {
        return paginatedData ? this._addCursorsToData(instagramReelsData, cursors) : instagramReelsData;
      }

      instagramReelsData = await this._appendPostModelToPostData.perform(instagramReelsData);
      const filteredPosts = filterTimeSeries(A(instagramReelsData), startDate.unix(), endDate.unix(), 'created_time');
      const reformattedPosts = filteredPosts.map((reel) => createIgReelFromDynamo(reel, this.socialProfile));

      return paginatedData ? this._addCursorsToData(reformattedPosts, cursors) : reformattedPosts;
    });
  });

  /**
   * Returns Facebook posts for users.
   *
   * @property getAnalyticsFacebookPosts
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {Boolean} [paginatedData=false]
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<DynamoFbPost>} Facebook posts in the given time range.
   */
  getAnalyticsFacebookPosts = restartableTask(
    async (startDate, endDate, limit, cursor = null, paginatedData = false, forceRefresh = false) => {
      const promises = {
        facebookPosts: paginatedData
          ? this.dynamoApi.getPaginatedMedia.linked().perform(startDate, endDate, limit, cursor)
          : this.dynamoApi.getMedia.linked().perform(forceRefresh)
      };

      return await RSVP.hashSettled(promises).then(({ facebookPosts }) => {
        let facebookPostsData = [];
        const { cursors } = facebookPosts.value;

        if (facebookPosts.state === 'fulfilled') {
          facebookPostsData = !Array.isArray(facebookPosts.value) ? facebookPosts.value.data : facebookPosts.value;
        } else if (facebookPosts.state === 'rejected') {
          return paginatedData ? this._addCursorsToData(facebookPostsData, cursors) : facebookPostsData;
        }

        const filteredPosts = filterTimeSeries(A(facebookPostsData), startDate.unix(), endDate.unix(), 'created_time');
        const reformattedPosts = filteredPosts.map((post) => createFacebookPostFromDynamo(post, this.socialProfile));

        return paginatedData ? this._addCursorsToData(reformattedPosts, cursors) : reformattedPosts;
      });
    }
  );

  /**
   * Returns Tiktok posts for users.
   *
   * @property getAnalyticsTiktokPosts
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {Boolean} [paginatedData=false]
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<TiktokPost>} Tiktok posts in the given time range.
   */
  getAnalyticsTiktokPosts = restartableTask(
    async (startDate, endDate, limit, cursor = null, paginatedData = false, forceRefresh = false) => {
      const promises = {
        tiktokPosts: paginatedData
          ? this.dynamoApi.getPaginatedMedia.linked().perform(startDate, endDate, limit, cursor)
          : this.dynamoApi.getMedia.linked().perform(forceRefresh)
      };

      return await RSVP.hashSettled(promises).then(({ tiktokPosts }) => {
        let tiktokPostsData = [];
        const { cursors } = tiktokPosts.value;

        if (tiktokPosts.state === 'fulfilled') {
          tiktokPostsData = !Array.isArray(tiktokPosts.value) ? tiktokPosts.value.data : tiktokPosts.value;
        } else if (tiktokPosts.state === 'rejected') {
          return paginatedData ? this._addCursorsToData(tiktokPostsData, cursors) : tiktokPostsData;
        }

        const filteredPosts = filterTimeSeries(A(tiktokPostsData), startDate.unix(), endDate.unix(), 'created_time');
        const reformattedPosts = filteredPosts.map((post) => createTiktokPostFromDynamo(post, this.socialProfile));

        return paginatedData ? this._addCursorsToData(reformattedPosts, cursors) : reformattedPosts;
      });
    }
  );

  /**
   * Returns Facebook posts for users.
   *
   * @property getAnalyticsThreadsPosts
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {Boolean} [paginatedData=false]
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<DynamoFbPost>} Facebook posts in the given time range.
   */
  getAnalyticsThreadsPosts = restartableTask(
    async (startDate, endDate, limit, cursor = null, paginatedData = false, forceRefresh = false) => {
      const promises = {
        threadsPosts: paginatedData
          ? this.dynamoApi.getPaginatedMedia.linked().perform(startDate, endDate, limit, cursor)
          : this.dynamoApi.getMedia.linked().perform(forceRefresh)
      };

      return await RSVP.hashSettled(promises).then(({ threadsPosts }) => {
        let threadsPostsData = [];
        const { cursors } = threadsPosts.value;

        if (threadsPosts.state === 'fulfilled') {
          threadsPostsData = !Array.isArray(threadsPosts.value) ? threadsPosts.value.data : threadsPosts.value;
        } else if (threadsPosts.state === 'rejected') {
          return paginatedData ? this._addCursorsToData(threadsPostsData, cursors) : threadsPostsData;
        }

        const filteredPosts = filterTimeSeries(A(threadsPostsData), startDate.unix(), endDate.unix(), 'created_time');
        const reformattedPosts = filteredPosts.map((post) => createThreadsPostFromDynamo(post, this.socialProfile));

        return paginatedData ? this._addCursorsToData(reformattedPosts, cursors) : reformattedPosts;
      });
    }
  );

  /**
   * Returns all Threads posts in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingThreadsPostData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of posts to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<DynamoFbPost>)} accumulator Current accumulated posts
   *
   * @return {Array<DynamoFbPost>} Threads posts in the given time range.
   */
  continueLoadingThreadsPostData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const isPaginated = true;
    const { cursors, data } = await this.getAnalyticsThreadsPosts.perform(
      startDate,
      endDate,
      limit,
      cursor,
      isPaginated
    );
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingThreadsPostData.perform(startDate, endDate, limit, cursors.next, accumulator);
    }

    const processedPosts = this.formattersThreadsPostsTable.processPosts(
      [...accumulator].reverse(),
      startDate,
      endDate,
      this.analyticsAccess.features.analyticsStandard
    );

    return await { graphData: processedPosts };
  });

  /**
   * Returns all Instagram posts in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingInstagramPostData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of posts to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<IgPost>)} accumulator Current accumulated posts
   *
   * @return {Array<IgPost>} Instagram posts in the given time range.
   */
  continueLoadingInstagramPostData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const isPaginated = true;
    const { cursors, data } = await this.getAnalyticsStandardPosts.perform(
      startDate,
      endDate,
      limit,
      cursor,
      isPaginated
    );
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingInstagramPostData.perform(startDate, endDate, limit, cursors.next, accumulator);
    }

    const processedPosts = this.formattersIGPostTable.processPosts(
      [...accumulator].reverse(),
      startDate,
      endDate,
      this.analyticsAccess.features.analyticsStandard
    );

    return await { graphData: processedPosts };
  });

  /**
   * Returns all Instagram reels in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingInstagramReelData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of reels to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<DynamoIgReel>)} accumulator Current accumulated reels
   *
   * @return {Array<DynamoIgReel>} Instagram reels in the given time range.
   */
  continueLoadingInstagramReelData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const isPaginated = true;
    const { cursors, data } = await this.getInstagramReels.perform(startDate, endDate, limit, cursor, isPaginated);
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingInstagramReelData.perform(startDate, endDate, limit, cursors.next, accumulator);
    }

    const processedReels = this.formattersIGReelTable.processReels(
      [...accumulator].reverse(),
      startDate,
      endDate,
      this.analyticsAccess.features.analyticsStandard
    );

    return await { graphData: processedReels };
  });

  /**
   * Returns all Facebook posts in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingFacebookPostData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of posts to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<DynamoFbPost>)} accumulator Current accumulated posts
   *
   * @return {Array<DynamoFbPost>} Facebook posts in the given time range.
   */
  continueLoadingFacebookPostData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const isPaginated = true;
    const { cursors, data } = await this.getAnalyticsFacebookPosts.perform(
      startDate,
      endDate,
      limit,
      cursor,
      isPaginated
    );
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingFacebookPostData.perform(startDate, endDate, limit, cursors.next, accumulator);
    }

    const processedPosts = this.formattersFacebookPostTable.processPosts(
      [...accumulator].reverse(),
      startDate,
      endDate,
      this.analyticsAccess.features.analyticsStandard
    );

    return await { graphData: processedPosts };
  });

  /**
   * Returns all Tiktok posts in a selected time range for analytics standard users for use with Export CSV.
   *
   * @property continueLoadingTiktokPostData
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   * @param {(Number)} limit Maximum number of posts to return per page
   * @param {(String)} cursor Current pagination cursor position
   * @param {(Array<IgPost>)} accumulator Current accumulated posts
   *
   * @return {Array<IgPost>} Tiktok posts in the given time range.
   */
  continueLoadingTiktokPostData = task(async (startDate, endDate, limit, cursor, accumulator = []) => {
    const isPaginated = true;
    const { cursors, data } = await this.getAnalyticsTiktokPosts.perform(
      startDate,
      endDate,
      limit,
      cursor,
      isPaginated
    );
    accumulator.push(...data);

    if (cursors.next) {
      return await this.continueLoadingTiktokPostData.perform(startDate, endDate, limit, cursors.next, accumulator);
    }

    const processedPosts = this.formattersTiktokPostTable.processPosts(
      [...accumulator].reverse(),
      startDate,
      endDate,
      this.analyticsAccess.features.analyticsStandard
    );

    return await { graphData: processedPosts };
  });

  /**
   * Gets Linkinbio posts from the store service for the current social profile.
   *
   * @property getStoreLinkinbioPosts
   *
   * @returns {Array<LIBPost>}  Linkinbio posts for the current social profile
   */
  getStoreLinkinbioPosts = task(async () => {
    if (!this.socialProfile?.isInstagram) {
      return [];
    }

    return await this.store.query('linkinbio-post', {
      instagram_profile_id: this.socialProfile.id
    });
  });

  /**
   * Calls the dynamo api to message squirtle to track given media
   *
   * @property analyticsTrackMedia
   * @param {Number} mediaId Media ID
   * @param {Number} socialProfileId The socialProfile.id making the request
   *
   * @return {Promise}
   */
  analyticsTrackMedia = task(async (mediaId, socialProfileId) => {
    return await this.dynamoApi.getAnalyticsTrackMedia.linked().perform(mediaId, socialProfileId);
  });

  /** Returns a formatted, filtered version of analytics standard posts
   *
   * @method _filterAndReformatPosts
   * @param {Array} analyticsStandardPosts All media from dynamo for given social profile
   * @param {(Moment|Date)} startDate Start of interval
   * @param {(Moment|Date)} endDate End of interval
   *
   * @returns {Array<DynamoIgPost>} Formatted, filtered version of analytics standard posts
   * @protected
   */
  _filterAndReformatPosts(analyticsStandardPosts, startDate, endDate) {
    const filteredMedia = filterTimeSeries(A(analyticsStandardPosts), startDate.unix(), endDate.unix(), 'created_time');

    if (!filteredMedia) {
      return null;
    }

    const reformattedMedia = filteredMedia.map((post) => createIgPostFromDynamo(post, this.socialProfile));
    return reformattedMedia;
  }

  /** Logs keen error with the required error data.
   *
   * @method _handleKeenError
   * @param {String} reason Error reason
   * @param {SocialProfile} socialProfile
   *
   * @protected
   */
  _handleKeenError(reason, socialProfile, type) {
    const messageDetail = `analytics keen load fail: ${type}`;
    const { keenReadKey } = this.socialProfile;
    this.errors.log(reason, {
      messageDetail,
      reason,
      keenReadKey
    });
  }

  /** Adds hashtags to tags property on IgPost for graph API responses.
   *
   * @method _addHashtagsToMedia
   * @return {IgPost} Raw instagram post
   *
   * @return {IgPost} Instagram post with tags in caption
   * @protected
   */
  _addHashtagsToMedia(media) {
    const isGraphResponse = this.isProfessional && media && media.igId;

    if (isGraphResponse) {
      let tags = media && getHashtags(media.captionText);
      tags = tags.map((tag) => `#${tag}`);
      return EmberObject.create(Object.assign({}, media, { tags }));
    }

    return media;
  }

  /** Adds cursors property on response
   *
   * @method _addCursorsToData
   * @param {Array<IgPost>} data Instagram posts in the given time range
   * @param {Object} cursors Relevant pagination cursors
   *
   * @return {Object} Final object containing both the data and cursors
   * @protected
   */
  _addCursorsToData(data, cursors) {
    return { data, cursors };
  }

  _appendPostModelToPostData = task(async (analyticsStandardPostsData) => {
    if (this.socialProfile?.canHighPerformingPost || this.socialProfile?.canBoostPost) {
      const mediaIds = analyticsStandardPostsData.map((postData) => postData.id);

      if (isPresent(mediaIds)) {
        try {
          const queryPosts = await this.store.query('gram', {
            social_profile_id: this.socialProfile.id,
            media_ids: mediaIds
          });
          analyticsStandardPostsData.map(
            (standardPost) =>
              (standardPost.scheduledPost = queryPosts.filter(
                (queryPost) => queryPost.mediaId === standardPost.id
              ).firstObject)
          );
        } catch (error) {
          this.errors.log(new Error('Error while querying gram modal on Analytics Post Data'), {
            social_profile_id: this.socialProfile.id,
            media_ids: mediaIds
          });
        }
      }
    }

    return analyticsStandardPostsData;
  });
}
