export const availableColors = [
  'salmon',
  'blue',
  'lime',
  'purple',
  'yellow',
  'igpink',
  'twblue',
  'green',
  'fbblue',
  'pinred',
  'ytred',
  'black'
];
