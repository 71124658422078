import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

import { DiscoveryListTask, SocialProfileType } from 'later/utils/constants';
import loadScript from 'later/utils/load-script';
import { convert, timestamp } from 'later/utils/time-format';
import { PlanInterval } from 'shared/types/plans';

import type ArrayProxy from '@ember/array/proxy';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type SocialProfileModel from 'later/models/social-profile';
import type SubscriptionModel from 'later/models/subscription';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type IntegrationsQueueService from 'later/services/integrations-queue';
import type { Integration } from 'later/services/integrations-queue';
import type SubscriptionsService from 'later/services/subscriptions';
import type { UntypedService } from 'shared/types';

type SegmentString = string | null;

type MetaFields = Record<string, any> | undefined;

type SocialProfilePostInfo<T extends SocialProfileType> = {
  [K in `${T}_posts_total`]: 'not connected' | 'unlimited' | number;
} & {
  [K in `${T}_posts_used`]: 'not connected' | 'unlimited' | number;
} & {
  [K in `${T}_posts_remaining`]: 'not connected' | 'unlimited' | number;
};

type SocialProfileListAndInfo<T extends SocialProfileType> =
  | {
      [K in `${T}`]: string;
    }
  | ({
      [K in `${T}_profile_name`]: string;
    } & {
      [K in `${T}_profile_status`]:
        | 'Connected'
        | 'Connected: Enable Permissions'
        | 'Expired: Refresh Profile'
        | 'Connected: Enable Auto Publish';
    } & {
      [K in `${T}_follow_count`]: number;
    } & {
      [K in `${T}_profile_type`]: 'personal' | 'creator' | 'business';
    });

interface UserAndAccountTraits {
  account_owner: boolean;
  activated_trial: boolean;
  active_trial: boolean;
  ai_credits_total: number;
  ai_credits_used: number;
  ai_credits_remaining: number;
  annual_user_initial_order_within_thirty_days: boolean;
  annual_user_renewed_over_thirty_days: boolean;
  billing_email: string;
  bttp_enabled: boolean;
  current_free_rollout: string;
  current_plan_rollout: string;
  email: string;
  experiment_variants_string: string;
  experiment_variants: string[] | [];
  first_thirty_days: boolean;
  force_migrated_at: string | null;
  has_business_profile: boolean;
  has_opened_chatbot_previously: boolean;
  initial_free_rollout: string;
  language: string;
  linkinbio_set_up: boolean;
  monthly_user_completed_order_over_seven_days: boolean;
  more_than_5_social_sets: boolean;
  number_of_social_sets: boolean;
  number_of_users: boolean;
  plan_interval: string;
  plan_name: string;
  plan_type: string;
  subscription_provider: string;
  support_segment: string;
  user_experiment_variants_string: string;
  user_experiment_variants: string[] | [];
  user_name: string;
  user_plan_name: string;
}

export default class AdaService extends Service implements Integration {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: UntypedService;
  @service declare router: RouterService;
  @service declare segment: UntypedService;
  @service declare store: StoreService;
  @service declare subscriptions: SubscriptionsService;
  @service declare integrationsQueue: IntegrationsQueueService;
  @service declare intl: IntlService;

  readonly name = 'ada';

  @tracked isOpen = false;
  @tracked currentRouteName = '';
  @tracked adaHandle = 'later';

  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    this.router.on('routeDidChange', (transition) => {
      // TEMP: Having to check each value to prevent an error. Need to refactor ada widget mounting.
      if (transition && transition.to && transition.to.name) {
        const newHandle = this.#isOnOCE(transition.to.name) ? 'later-influence' : 'later';

        if (newHandle !== this.adaHandle) {
          this.adaHandle = newHandle;
          window.adaSettings.handle = newHandle;
          this.#restartAdaEmbed();
        }
      }
    });
  }

  get adaContainer(): HTMLDivElement | null {
    return document.querySelector('#ada-entry');
  }

  get hasActiveTrial(): boolean {
    return this.subscriptions.hasActiveTrial;
  }

  get hasOpenedChatbotPreviously(): boolean {
    return isPresent(
      this.store.peekAll('discovery-list-task').find(({ identifier }) => identifier === DiscoveryListTask.OpenedChatbot)
    );
  }

  get subscription(): SubscriptionModel | Record<string, never> {
    return this.subscriptions.subscription;
  }

  async fetchUserAndAccountTraits(): Promise<UserAndAccountTraits | undefined> {
    let userAndAccountTraits;

    try {
      userAndAccountTraits = await this.segment.fetchUserAndAccountTraits.perform();
    } catch {
      return;
    }

    const { user, account } = userAndAccountTraits;

    const {
      activated_trial,
      active_trial,
      number_of_ai_credits: ai_credits_total,
      number_of_ai_credits_used: ai_credits_used,
      number_of_ai_credits_left: ai_credits_remaining,
      billing_email,
      bttp_enabled,
      created_at: account_created_at,
      current_free_rollout,
      current_plan_rollout,
      experiment_variants,
      experiment_variants_string,
      force_migrated_at,
      has_business_profile,
      initial_free_rollout,
      plan_interval,
      plan_name,
      plan_type,
      subscription_provider_name
    } = account;

    const {
      account_owner,
      email,
      has_enabled_linkinbio: linkinbio_set_up,
      name: user_name,
      plan_name: user_plan_name,
      experiment_variants: user_experiment_variants,
      experiment_variants_string: user_experiment_variants_string,
      language,
      support_segment,
      number_of_social_sets,
      number_of_users
    } = user;

    const has_opened_chatbot_previously = this.hasOpenedChatbotPreviously;
    const more_than_5_social_sets = number_of_social_sets > 5;
    const first_thirty_days = Boolean(
      account_created_at && new Date(account_created_at) > new Date(Date.now() - convert.days(30).toMilliseconds())
    );

    const subscription_provider = subscription_provider_name === 'Apple' ? 'iOS' : 'Web';

    const socialProfiles = await this.store.findAll('social-profile');

    const profilePosts = Object.assign(
      {},
      ...Object.values(SocialProfileType).map((profile) => this.#getSocialProfilePostInfo(profile, socialProfiles))
    );

    const profileListAndInfo = Object.assign(
      {},
      ...Object.values(SocialProfileType).map((profile) => this.#getSocialProfileListAndInfo(profile, socialProfiles))
    );

    const subscriptionInfo = this.#getSubscriptionInfo(plan_interval);

    // for grandfathered livechat
    const can_live_chat = this.auth.currentAccount.canChat;

    return {
      account_owner,
      activated_trial,
      active_trial,
      ai_credits_total,
      ai_credits_used,
      ai_credits_remaining,
      billing_email,
      bttp_enabled,
      can_live_chat,
      current_free_rollout,
      current_plan_rollout,
      email,
      experiment_variants_string,
      experiment_variants,
      first_thirty_days,
      force_migrated_at,
      has_business_profile,
      has_opened_chatbot_previously,
      initial_free_rollout,
      language,
      linkinbio_set_up,
      more_than_5_social_sets,
      number_of_social_sets,
      number_of_users,
      plan_interval,
      plan_name,
      plan_type,
      ...profileListAndInfo,
      ...profilePosts,
      ...subscriptionInfo,
      subscription_provider,
      support_segment,
      user_experiment_variants_string,
      user_experiment_variants,
      user_name,
      user_plan_name
    };
  }

  async setup(): Promise<void> {
    // Note: canAdaChatbot is set to false for certain staging QA users
    // to prevent widget from interfering with locators on auto tests
    if (!this.auth.currentAccount?.canAdaChatbot) {
      return;
    }

    window.adaSettings = {
      handle: 'later',
      adaReadyCallback: () => {
        /**
         * Ada is slow to load so integrationsQueue may have set up and processed
         * an item in the queue already. So Ada may need to be hidden when loaded
         */
        this.integrationsQueue.refreshIntegrations();
      },
      toggleCallback: async (isDrawerOpen: boolean) => {
        this.isOpen = isDrawerOpen;
        if (isDrawerOpen) {
          this.integrationsQueue.addToQueue(this.name, true);
          await this.#setUserAndAccountDetails();
          this.#trackOpened();
        } else {
          this.integrationsQueue.removeFromQueue(this.name);
        }
      }
    };

    try {
      await loadScript('https://static.ada.support/embed2.js', {
        id: '__ada'
      });
      await this.#setUserAndAccountDetails();
    } catch (error) {
      this.errors.log('Ada failed to load', error);
      this.#displayError();
    }
  }

  show(): void {
    if (this.adaContainer) {
      this.adaContainer.style.display = '';
    }
  }

  hide(): void {
    if (this.adaContainer) {
      this.adaContainer.style.display = 'none';
    }
  }

  open(location: SegmentString = null, page: SegmentString = null): void {
    if (!this.isOpen) {
      window.adaEmbed?.toggle();
      this.#trackManualOpen(location, page);
    }
  }

  close(): void {
    if (this.isOpen) {
      window.adaEmbed?.toggle();
    }
  }

  closeCampaign(): void {
    window.adaEmbed?.closeCampaign();
  }

  reset(): void {
    window.adaEmbed?.reset();
  }

  trackEvent(name: string): void {
    window.adaEmbed?.trackEvent(name);
  }

  triggerCampaign(campaignKey: string, metaFields?: MetaFields): void {
    this.setMetaFields(metaFields);
    window.adaEmbed?.triggerCampaign(campaignKey);
  }

  setMetaFields(metaFields: MetaFields): void {
    if (metaFields) {
      window.adaEmbed?.setMetaFields(metaFields);
    }
  }

  #getSocialProfilePostInfo<T extends SocialProfileType>(
    profileType: T,
    profiles: ArrayProxy<SocialProfileModel>
  ): SocialProfilePostInfo<T> {
    const hasProfile = profiles.filter((profile) => profile.profileType === profileType).length > 0;

    const postsLeftForSocialProfile = `${profileType}PostsLeft` as keyof AccountModel;
    const hasUnlimitedPosts = !this.auth.currentAccount.maxPosts;

    let max: string | number = this.auth.currentAccount.maxPosts || 0;
    let postsLeft: string | number = (this.auth.currentAccount[postsLeftForSocialProfile] as number) || 0;
    let postsUsed: string | number = max - postsLeft;

    if (!hasProfile) {
      max = 'not connected';
      postsLeft = 'not connected';
      postsUsed = 'not connected';
    } else if (hasUnlimitedPosts) {
      max = 'unlimited';
      postsLeft = 'unlimited';
      postsUsed = 'unlimited';
    }

    const obj = {
      [`${profileType}_posts_total`]: max,
      [`${profileType}_posts_used`]: postsUsed,
      [`${profileType}_posts_remaining`]: postsLeft
    } as SocialProfilePostInfo<T>;
    return obj;
  }

  #getSocialProfileListAndInfo<T extends SocialProfileType>(
    socialProfileType: T,
    socialProfiles: ArrayProxy<SocialProfileModel>
  ): SocialProfileListAndInfo<T> {
    const selectedSocialProfiles = socialProfiles.filter(
      (socialProfile: SocialProfileModel) => socialProfile.profileType === socialProfileType
    );

    if (selectedSocialProfiles.length === 0) {
      return {
        [`${socialProfileType}`]: 'not connected'
      } as SocialProfileListAndInfo<T>;
    }

    if (selectedSocialProfiles.length === 1) {
      const {
        canSelectAutoPublish,
        defaultAutoPublish: hasEnabledAutoPublish,
        displayName,
        followedBy,
        hasInstagramAutoPublishScope,
        hasTiktokAutoPublishScope,
        hasUnconnectedProfessional,
        isBusiness,
        isCreator,
        isInstagram,
        isLinkedin,
        isMissingLinkedinPermissions,
        isTiktok,
        isTwitter,
        needsProfessionalTokenRefresh,
        needsRefresh
      } = selectedSocialProfiles[0];
      let socialProfileName = '';
      let profileStatus = 'Connected';
      let profileType = 'personal';

      if (needsRefresh) {
        profileStatus = 'Expired: Refresh Profile';
      } else if (!hasEnabledAutoPublish && canSelectAutoPublish) {
        profileStatus = 'Connected: Enable Auto Publish';
      } else if (
        (isMissingLinkedinPermissions && isLinkedin) ||
        (!hasInstagramAutoPublishScope && isInstagram) ||
        (!hasTiktokAutoPublishScope && isTiktok)
      ) {
        profileStatus = 'Connected: Enable Permissions';
      } else if (isCreator && (hasUnconnectedProfessional || needsProfessionalTokenRefresh)) {
        profileStatus = 'Connected: Enable Creator Profile';
      }

      if (isCreator) {
        profileType = 'creator';
      } else if (isBusiness) {
        profileType = 'business';
      }

      if (isInstagram || isTiktok || isTwitter) {
        socialProfileName = displayName;
      } else {
        socialProfileName = `${socialProfileType}/${displayName}`;
      }

      return {
        [`${socialProfileType}_profile_name`]: socialProfileName,
        [`${socialProfileType}_profile_status`]: profileStatus,
        [`${socialProfileType}_follow_count`]: followedBy,
        [`${socialProfileType}_profile_type`]: profileType
      } as SocialProfileListAndInfo<T>;
    }

    return {
      [`${socialProfileType}`]: `${selectedSocialProfiles.length} connected`
    } as SocialProfileListAndInfo<T>;
  }

  #getSubscriptionInfo(planInterval: string): {
    annual_user_initial_order_within_thirty_days: boolean;
    annual_user_renewed_over_thirty_days: boolean;
    monthly_user_completed_order_over_seven_days: boolean;
  } {
    let annual_user_initial_order_within_thirty_days = false;
    let annual_user_renewed_over_thirty_days = false;
    let monthly_user_completed_order_over_seven_days = false;

    if (this.subscription && this.subscription.isActive) {
      const lastPaymentDate = this.subscription.planModifiedMoment;
      const daysSinceLastPayment = moment().diff(lastPaymentDate, 'days');
      const daysSinceSubscriptionRenewal = moment().diff(this.subscription?.renewalDate, 'days');

      annual_user_initial_order_within_thirty_days =
        planInterval === PlanInterval.Yearly &&
        daysSinceLastPayment < 30 &&
        moment.unix(this.subscription?.createdAt).year() === moment().year();

      annual_user_renewed_over_thirty_days = planInterval === PlanInterval.Yearly && daysSinceSubscriptionRenewal > 30;

      monthly_user_completed_order_over_seven_days = planInterval === PlanInterval.Monthly && daysSinceLastPayment > 7;
    }

    return {
      annual_user_initial_order_within_thirty_days,
      annual_user_renewed_over_thirty_days,
      monthly_user_completed_order_over_seven_days
    };
  }

  #saveOpenedChatbot(): void {
    if (!this.hasOpenedChatbotPreviously) {
      const item = this.store.createRecord('discovery-list-task', {
        identifier: DiscoveryListTask.OpenedChatbot,
        completedTime: timestamp(),
        user: this.auth.currentUserModel
      });
      item.save();
    }
  }

  #trackManualOpen(location: SegmentString, page: SegmentString): void {
    this.segment.track('user-opened-chabot', {
      location,
      active_trial: !!this.hasActiveTrial,
      page
    });
    this.#saveOpenedChatbot();
  }

  #trackOpened(): void {
    this.segment.track('chatbot-opened', { active_trial: !!this.hasActiveTrial });
    this.#saveOpenedChatbot();
  }

  #displayError(): void {
    this.alerts.warning(htmlSafe(this.intl.t('alerts.customer_support.error.service_not_available.message')), {
      title: this.intl.t('alerts.customer_support.error.service_not_available.title')
    });
  }

  async #setUserAndAccountDetails(): Promise<void> {
    const metaFields = await this.fetchUserAndAccountTraits();
    this.setMetaFields(metaFields);
  }

  #isOnOCE(newRouteName: string): boolean {
    const validRoutes = [
      'cluster.media-kit', // Media-kit
      'cluster.partnerships.profile.join', // Database opt-in page
      'cluster.partnerships.profile.view-campaigns.campaign', // OCE campaigns pages
      'cluster.partnerships.campaign-application', // OCE application pages
      'cluster.partnerships.profile.campaigns', // Find campaigns
      'cluster.partnerships.profile.manage-campaigns', // Manage campaigns
      'cluster.partnerships.create-influencer' // Create influencers
    ];

    // If on brand side, don't show creator oce
    if (newRouteName.includes('.profiles.')) {
      return false;
    }

    // Creator home, this will be deprecated in the future when legacy campaigns are removed.
    if (newRouteName === 'cluster.partnerships.profile.index') {
      return true;
    }

    return validRoutes.some((route) => newRouteName.startsWith(route));
  }

  #restartAdaEmbed(): void {
    window.adaEmbed?.stop();
    window.adaEmbed?.start(window.adaSettings);
  }
}

declare module '@ember/service' {
  interface Registry {
    ada: AdaService;
  }
}
