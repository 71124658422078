import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type { Transition } from 'shared/types';

export default class PartnershipsRoute extends Route {
  @service declare auth: AuthService;
  @service declare router: RouterService;

  get group(): GroupModel | undefined {
    return this.auth.lastGroup;
  }

  get groupSlug(): string | undefined {
    return this.group?.slug;
  }

  get socialIdentity(): SocialIdentityModel | undefined {
    const identity = this.group?.socialIdentities.firstObject;
    return identity;
  }

  redirect(_model: never, transition: Transition): void {
    const { feature } = transition.to.params;

    if (!this.groupSlug || !this.socialIdentity) {
      this.router.transitionTo('/');
      return;
    }

    switch (feature) {
      case 'partnership':
        this.router.transitionTo('cluster.partnerships', this.groupSlug);
        break;

      case 'creator':
        this.router.transitionTo('cluster.partnerships.profile', this.groupSlug, this.socialIdentity.id);
        break;

      case 'creator_campaigns':
        this.router.transitionTo('cluster.partnerships.profile.campaigns', this.groupSlug, this.socialIdentity.id);
        break;

      case 'creator_campaign_manager':
        this.router.transitionTo(
          'cluster.partnerships.profile.manage-campaigns',
          this.groupSlug,
          this.socialIdentity.id
        );
        break;

      case 'brand':
        this.router.transitionTo('cluster.partnerships.profiles', this.groupSlug, this.socialIdentity.id);
        break;

      case 'brand_search':
        this.router.transitionTo(
          'cluster.partnerships.profiles.creators.search',
          this.groupSlug,
          this.socialIdentity.id
        );
        break;

      case 'brand_campaigns':
        this.router.transitionTo('cluster.partnerships.profiles.campaigns', this.groupSlug, this.socialIdentity.id);
        break;

      default:
        this.router.transitionTo('/');
    }
  }
}
