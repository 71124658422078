import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import moment from 'moment';

import * as plansData from 'later/utils/plans-data';
import enterprisePlanData from 'later/utils/static-data/enterprise-plan';

import type AuthService from './auth';
import type SubscriptionsService from './subscriptions';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type SubscriptionModel from 'later/models/subscription';
import type SubscriptionPlanModel from 'later/models/subscription-plan';
import type ErrorsService from 'later/services/errors';
import type { Maybe, UntypedService } from 'shared/types';
import type {
  PlanType,
  Feature,
  FeatureGroup,
  Highlights,
  LegacyPlan,
  Plan,
  PlanFeatures,
  PlanTemplate
} from 'shared/types/plans';

export default class PlansService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare locale: UntypedService;
  @service declare subscriptions: SubscriptionsService;
  @service declare store: StoreService;

  trialOptInPlanNames = ['starter', 'growth', 'advanced'];
  mobileTrialOptInPlanNames = ['starter', 'growth'];
  agencyPlanNames: PlanType[] = ['scale-15', 'scale-30'];

  enterpriseSubscriptionPlan: SubscriptionPlanModel;

  constructor(properties?: object) {
    super(properties);

    this.enterpriseSubscriptionPlan = this.store.createRecord('subscription-plan', enterprisePlanData(this.intl));
  }

  get agencyPlans(): Maybe<Plan>[] {
    return this.agencyPlanNames.map((planType) => this.getPlan(planType));
  }

  get featureGroups(): FeatureGroup[] {
    return plansData.featureGroups(this.intl);
  }

  get keyFeatures(): Feature[] {
    return plansData.keyFeatures(this.intl);
  }

  get legacyPlans(): LegacyPlan[] {
    return plansData.legacyPlans(this.intl);
  }

  get newFeatures(): string[] {
    return plansData.newFeatures(this.intl);
  }

  get highlightsMap(): Record<PlanType, (plan?: SubscriptionPlanModel) => Highlights> {
    return plansData.highlightsMap(this.intl, this.locale);
  }

  get planFeatures(): Record<string, (plan?: SubscriptionPlanModel) => PlanFeatures> {
    return plansData.planFeatures(this.intl);
  }

  get _planTemplates(): PlanTemplate[] {
    return [
      {
        for: this.intl.t('plans.free.for'),
        highlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('plans.free.highlights.highlight_2'),
          this.intl.t('plans.free.highlights.highlight_3'),
          this.intl.t('plans.free.highlights.highlight_4'),
          this.intl.t('plans.free.highlights.highlight_5')
        ],
        planType: 'free',
        active: true,
        name: this.intl.t('plans.plan_names.free'),
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.basics.for'),
        highlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('plans.basics.highlights.highlight_2'),
          this.intl.t('plans.basics.highlights.highlight_3'),
          this.intl.t('plans.basics.highlights.highlight_4'),
          this.intl.t('plans.basics.highlights.highlight_5'),
          this.intl.t('plans.basics.highlights.highlight_6')
        ],
        name: this.intl.t('plans.plan_names.basics'),
        planType: 'basics',
        active: false,
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.creator.for'),
        highlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('plans.starter.highlights.highlight_2'),
          this.intl.t('plans.starter.highlights.highlight_3'),
          this.intl.t('shared_phrases.schedule_stories'),
          this.intl.t('plans.starter.highlights.highlight_5'),
          this.intl.t('plans.starter.highlights.highlight_6'),
          this.intl.t('shared_phrases.first_comment'),
          this.intl.t('shared_phrases.hashtag_suggestions'),
          this.intl.t('shared_phrases.multi_photo_auto_publish')
        ],
        migrationHighlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('plans.migrate.new.starter.feature_3'),
          this.intl.t('plans.migrate.new.starter.feature_4')
        ],
        name: this.intl.t('plans.plan_names.creator'),
        planType: 'creator',
        active: true,
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.starter.for'),
        highlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('plans.starter.highlights.highlight_2'),
          this.intl.t('plans.starter.highlights.highlight_3'),
          this.intl.t('shared_phrases.schedule_stories'),
          this.intl.t('plans.starter.highlights.highlight_5'),
          this.intl.t('plans.starter.highlights.highlight_6'),
          this.intl.t('shared_phrases.first_comment'),
          this.intl.t('shared_phrases.hashtag_suggestions'),
          this.intl.t('shared_phrases.multi_photo_auto_publish')
        ],
        migrationHighlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('plans.migrate.new.starter.feature_3'),
          this.intl.t('plans.migrate.new.starter.feature_4')
        ],
        name: this.intl.t('plans.plan_names.starter'),
        planType: 'starter',
        active: true,
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.growth.for'),
        highlights: [
          this.intl.t('shared_phrases.one_social_set'),
          this.intl.t('plans.growth.highlights.highlight_2'),
          this.intl.t('plans.growth.highlights.highlight_3'),
          this.intl.t('shared_phrases.schedule_stories'),
          this.intl.t('shared_phrases.linkinbio_generic'),
          this.intl.t('plans.growth.highlights.highlight_6'),
          this.intl.t('shared_phrases.first_comment'),
          this.intl.t('plans.growth.highlights.highlight_8')
        ],
        planType: 'growth',
        active: true,
        migrationHighlights: [
          this.intl.t('plans.migrate.new.growth.feature_1'),
          this.intl.t('plans.migrate.new.growth.feature_2'),
          this.intl.t('plans.migrate.new.growth.feature_3')
        ],
        name: this.intl.t('plans.plan_names.growth'),
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.advanced.for'),
        highlights: [
          this.intl.t('plans.advanced.highlights.highlight_1'),
          this.intl.t('plans.advanced.highlights.highlight_2'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('shared_phrases.schedule_stories'),
          this.intl.t('shared_phrases.linkinbio_generic'),
          this.intl.t('plans.advanced.highlights.highlight_6'),
          this.intl.t('shared_phrases.first_comment'),
          this.intl.t('plans.advanced.highlights.highlight_8')
        ],
        planType: 'advanced',
        active: true,
        migrationHighlights: [
          this.intl.t('plans.migrate.new.advanced.feature_3'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('plans.migrate.new.advanced.feature_3')
        ],
        name: this.intl.t('plans.plan_names.advanced'),
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.enterprise.for'),
        highlights: [
          this.intl.t('plans.advanced.highlights.highlight_1'),
          this.intl.t('plans.advanced.highlights.highlight_2'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('shared_phrases.schedule_stories'),
          this.intl.t('shared_phrases.linkinbio_generic'),
          this.intl.t('plans.advanced.highlights.highlight_6'),
          this.intl.t('shared_phrases.first_comment'),
          this.intl.t('plans.advanced.highlights.highlight_8')
        ],
        planType: 'enterprise',
        active: true,
        migrationHighlights: [
          this.intl.t('plans.migrate.new.advanced.feature_3'),
          this.intl.t('shared_phrases.unlimited_posts'),
          this.intl.t('plans.migrate.new.advanced.feature_3')
        ],
        name: this.intl.t('plans.plan_names.enterprise'),
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.scale-15.for'),
        highlights: [],
        planType: 'scale-15',
        active: true,
        name: this.intl.t('plans.plan_names.scale-15'),
        socialPlatform: '<div class="tPF--features__social"></div>'
      },
      {
        for: this.intl.t('plans.linkinbio.for'),
        highlights: [],
        migrationHighlights: [],
        name: this.intl.t('plans.plan_names.linkinbio'),
        planType: 'linkinbio',
        active: true,
        socialPlatform: '<div class="tPF--features__social"></div>'
      }
    ];
  }

  get subscriptionPlans(): SubscriptionPlanModel[] {
    return this.subscriptions.subscriptionPlans;
  }

  getPlan(planType: PlanType, isMonthly = true): Maybe<Plan> {
    if (planType?.includes('scale')) {
      return this.getAgencyPlan(planType, isMonthly);
    }
    return this._plans(isMonthly).find((plan) => plan.planType === planType);
  }

  getAgencyPlan(planType: PlanType, isMonthly = true): Maybe<Plan> {
    const monthlySubscriptionPlan = this.subscriptionPlans.find(
      (p) => p.planType === planType && p.isMonthly === true
    ) as SubscriptionPlanModel;
    const yearlySubscriptionPlan = this.subscriptionPlans.find(
      (p) => p.planType === planType && p.isMonthly === false
    ) as SubscriptionPlanModel;

    if (!monthlySubscriptionPlan || !yearlySubscriptionPlan) {
      return;
    }

    const subscriptionPlan = isMonthly ? monthlySubscriptionPlan : yearlySubscriptionPlan;

    const planFeatures: PlanFeatures[] = Object.keys(this.planFeatures).map((key) =>
      this.planFeatures[key](subscriptionPlan)
    );

    const planAmount = this.#getPlanAmount(monthlySubscriptionPlan, yearlySubscriptionPlan);

    return {
      planType: subscriptionPlan?.planType,
      name: subscriptionPlan?.displayName,
      socialPlatform: '<div class="tPF--features__social"></div>',
      ...Object.assign({}, ...planFeatures),
      ...planAmount,
      active: true
    };
  }

  get plans(): Plan[] {
    return this._plans().filter((plan) => plan.active);
  }

  get yearlyPlans(): Plan[] {
    const isMonthly = false;

    return this._plans(isMonthly).filter((plan) => plan.active);
  }

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get canSelectMonthlyInterval(): boolean {
    if (!this.currentAccount.isOnLegacyPlan) {
      return true;
    }

    if (this.subscriptions.subscriptionPlan?.isMonthly) {
      return true;
    }

    const activeUntil = this.subscriptions.subscription?.activeUntilTime ?? 0;
    const cutoffDate = moment(activeUntil * 1000)
      .subtract(2, 'months')
      .unix();
    const currentDate = Math.floor(Date.now() / 1000);

    return currentDate >= cutoffDate;
  }

  getCustomPlanDetails(customPlan: SubscriptionModel): {
    name: string;
    price: string;
    migrationPlan: 'advanced';
    migrationHighlights: string[];
  } {
    return {
      name: this.intl.t('plans.plan_names.custom'),
      price: (Number(customPlan.monthlyRate) / 100).toFixed(0),
      migrationPlan: 'advanced',
      migrationHighlights: [
        this.intl.t('plans.migrate.legacy.custom.feature_1', { numSocialSets: customPlan.totalSocialSets }),
        this.intl.t('shared_phrases.unlimited_posts'),
        this.intl.t('plans.migrate.legacy.custom.feature_3', { numUsers: customPlan.totalUsers })
      ]
    };
  }

  _plans(isMonthly = true): Plan[] {
    if (!this.subscriptionPlans?.length) {
      return this._planTemplates;
    }

    return this._planTemplates.map((plan) => {
      const monthlySubscriptionPlan = this.subscriptionPlans.find(
        (p) => p.planType === plan.planType && p.isMonthly === true
      ) as SubscriptionPlanModel;
      const yearlySubscriptionPlan = this.subscriptionPlans.find(
        (p) => p.planType === plan.planType && p.isMonthly === false
      ) as SubscriptionPlanModel;

      let subscriptionPlan: SubscriptionPlanModel;
      if (plan.planType === 'enterprise') {
        subscriptionPlan = this.enterpriseSubscriptionPlan;
      } else if (plan.planType === 'free') {
        //Note: free only ever has a monthly subscription plan
        subscriptionPlan = monthlySubscriptionPlan;
      } else {
        subscriptionPlan = isMonthly ? monthlySubscriptionPlan : yearlySubscriptionPlan;
      }

      if (!subscriptionPlan?.features && plan.planType !== 'enterprise') {
        return plan;
      }

      // Note: scale plans are not in the highlightsMap.
      const planHighlights = this.highlightsMap[plan.planType]
        ? this.highlightsMap[plan.planType](subscriptionPlan)
        : [];

      const planFeatures = Object.keys(this.planFeatures).map((key) => this.planFeatures[key](subscriptionPlan));

      let planAmount = {};
      if (subscriptionPlan.planType === 'free') {
        planAmount = this.#getPlanAmount(monthlySubscriptionPlan);
      } else if (subscriptionPlan.planType === 'enterprise') {
        planAmount = {};
      } else if (monthlySubscriptionPlan !== undefined && yearlySubscriptionPlan !== undefined) {
        planAmount = this.#getPlanAmount(monthlySubscriptionPlan, yearlySubscriptionPlan);
      } else {
        // Note: not sure if this scenario happens in prod. Possibly if enterprise plan is introduced again.
        this.errors.log('Tried to load planAmount without a monthly and yearly plan passed in');
      }

      return {
        ...plan,
        ...Object.assign({}, ...planFeatures),
        ...planAmount,
        ...planHighlights,
        active: subscriptionPlan?.active
      };
    });
  }

  #getPlanAmount(
    monthlySubscriptionPlan: SubscriptionPlanModel,
    yearlySubscriptionPlan?: SubscriptionPlanModel
  ): PlanFeatures {
    if (monthlySubscriptionPlan.planType === 'free') {
      return {
        // to change the type?
        yearlyDiscountPercent: '',
        yearlyPrice: 0,
        monthlyPrice: 0,
        monthlyPriceBilledYearly: 0
      };
    }
    if (yearlySubscriptionPlan === undefined) {
      assert('Must pass in monthlySubscriptionPlan and yearlySubscriptionPlan');
    }

    const discount = monthlySubscriptionPlan.amount * 12 - yearlySubscriptionPlan.amount;
    const yearlyDiscountPercent = ((discount / (monthlySubscriptionPlan.amount * 12)) * 100).toFixed(0);
    return {
      yearlyDiscountPercent,
      yearlyPrice: yearlySubscriptionPlan.amount,
      monthlyPrice: monthlySubscriptionPlan.amount,
      monthlyPriceBilledYearly: yearlySubscriptionPlan.amount !== 0 ? Math.round(yearlySubscriptionPlan.amount / 12) : 0
    };
  }

  async trialOptInPlans(mobile = false): Promise<Plan[]> {
    await this.subscriptions.getAllSubscriptionPlans();

    return mobile
      ? this.plans.filter((plan) => this.mobileTrialOptInPlanNames.includes(plan.planType))
      : this.plans.filter((plan) => this.trialOptInPlanNames.includes(plan.planType));
  }
}

declare module '@ember/service' {
  interface Registry {
    plans: PlansService;
  }
}
